import React, { useState } from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import {
  Box,
  Button,
  Popper,
  styled,
  Typography,
  useTheme,
} from "@mui/material";
import { Icon } from "@iconify/react";
import CustomModal from "../../components/modal/customModal";
import LoadingButton from "@mui/lab/LoadingButton";
import Logger from "../../lib/Logger";
import { axiosGetCall, axiosPostCall } from "../../services/apisCall";
import { fetchRefLoginPath } from "../../utils/common";
import { setItem } from "../../lib/cookies";
import {
  renderErrorMessage,
  renderSuccessMessage,
} from "../../lib/toastMessage";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useNavigate } from "react-router-dom";
import UserDetails from "./details";
import ActivityModal from "./activity";
import BroadcastActions from "./broadcast/broadcastActions";
import HierarchyModal from "./hierarchy";
import MeetActions from "./meet/meetActions";

const logger = new Logger("actionMenu");

const CustomMenuItem = styled(MenuItem)(({ theme }) => ({
  color: theme.palette.grey[800],
  gap: "8px",
}));

const PickersPopperRoot = styled(Popper, {
  name: "MuiPickersPopper",
  slot: "Root",
  overridesResolver: (_, styles) => styles.root,
})(() => ({
  zIndex: 9999,
}));

const ActionMenu = ({
  anchorEl,
  open,
  handleClose,
  data,
  fetchUsersList,
  type,
}) => {
  const { email, userStatus, products, permission } = data;
  const theme = useTheme();
  const [openDelete, setOpenDelete] = useState(false);
  const [forceLogout, setForceLogout] = useState(false);
  const [suspend, setSuspend] = useState(false);
  const [inactive, setInactive] = useState(false);
  const [active, setActive] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [suspendLoading, setSuspendLoading] = useState(false);
  const [forceLoading, setForceLoading] = useState(false);
  const [suspendDate, setSuspendDate] = useState("");

  const [userDetails, setUserDetails] = useState({});
  const [openDetails, setOpenDetails] = useState(false);
  const [openHierarchy, setOpenHierarchy] = useState(false);
  const [openActivity, setOpenActivity] = useState(false);

  const [productAnchorEl, setProductAnchorEl] = useState(null);
  const [currentActionProduct, setCurrentActionProduct] = useState(null);
  const openProductMenu = Boolean(productAnchorEl);

  const handleCloseProductMenu = () => {
    setProductAnchorEl(null);
    handleClose();
  };

  const navigate = useNavigate();

  const handleDDateChange = (date) => {
    setSuspendDate(+new Date(date));
  };

  const handleRefLogin = async () => {
    try {
      const params = {
        refEmail: email,
      };

      const { sessionToken, exp, token } = await axiosGetCall(
        "refLogin",
        params
      );

      setItem("refSecureToken", JSON.stringify([{ sessionToken, exp, token }]));
      const url = `${fetchRefLoginPath()}/u/0/refLogin`;
      window.open(url, "_blank", "noopener,noreferrer");
    } catch (error) {
      logger.error(error);
    }
  };

  const handleUserDetailsUpdate = () => {
    return navigate(`/${type}/updateUser`, {
      state: { userDetails: data, edit: true },
    });
  };

  const handleClickProductMenu = (id) => {
    setCurrentActionProduct(id);
    setProductAnchorEl(null);
  };

  const handleDelete = async () => {
    try {
      setDeleteLoading(true);
      const { message } = await axiosPostCall("deleteUser", { email });

      handleClose(false);
      setOpenDelete(false);
      renderSuccessMessage(message || "User deleted!");
      fetchUsersList();
    } catch (error) {
      logger.error(error);
      handleClose(false);
      setOpenDelete(false);
      renderErrorMessage(error?.message || "Network error!");
    } finally {
      setDeleteLoading(false);
    }
  };

  const handleClickForce = async () => {
    try {
      setForceLoading(true);
      const { message } = await axiosPostCall("forceLogout", { email });

      handleClose(false);
      setForceLogout(false);
      renderSuccessMessage(message || "User logged out!");
    } catch (error) {
      logger.error(error);
      handleClose(false);
      setForceLogout(false);
      renderErrorMessage(error?.message || "Network error!");
    } finally {
      setForceLoading(false);
    }
  };

  const handleSuspend = async (action) => {
    try {
      setSuspendLoading(true);
      const data = {
        email,
        status: action,
        updateHierarchy: false,
      };

      if (action === "suspend") data.supsendTime = suspendDate;
      if (action === "active" && userStatus === "suspend")
        data.updateHierarchy = true;
      const { message } = await axiosPostCall("suspendUser", data);

      handleClose(false);
      renderSuccessMessage(message || "User suspended!");
      fetchUsersList();
    } catch (error) {
      logger.error(error);
      handleClose(false);
      renderErrorMessage(error?.message || "Network error!");
    } finally {
      setSuspendLoading(false);
      setSuspend(false);
      setActive(false);
      setInactive(false);
    }
  };

  const deleteActions = () => (
    <Box
      sx={{
        mt: 3,
        mb: -2,
        width: "100%",
        display: "flex",
        flexDirection: "row",
        gap: "10px",
        justifyContent: "flex-end",
      }}
    >
      <LoadingButton
        size="small"
        onClick={handleDelete}
        startIcon={<Icon icon="mi:delete" />}
        loading={deleteLoading}
        loadingPosition="end"
        variant="contained"
        color="error"
        sx={{}}
      >
        <span>Delete</span>
      </LoadingButton>
      <Button
        variant="outlined"
        size="small"
        onClick={() => {
          setOpenDelete(false);
          handleClose(false);
        }}
      >
        Cancel
      </Button>
    </Box>
  );

  const forceActions = () => (
    <Box
      sx={{
        mt: 3,
        mb: -2,
        width: "100%",
        display: "flex",
        flexDirection: "row",
        gap: "10px",
        justifyContent: "flex-end",
      }}
    >
      <LoadingButton
        size="small"
        onClick={handleClickForce}
        startIcon={<Icon icon="tabler:logout" />}
        loading={forceLoading}
        loadingPosition="end"
        variant="contained"
        color="primary"
        sx={{ boxShadow: "none" }}
      >
        Logout
      </LoadingButton>
      <Button
        variant="outlined"
        size="small"
        onClick={() => {
          setForceLogout(false);
          handleClose(false);
        }}
      >
        Cancel
      </Button>
    </Box>
  );

  const suspendActions = (action) => (
    <Box
      sx={{
        mt: 3,
        mb: -2,
        width: "100%",
        display: "flex",
        flexDirection: "row",
        gap: "10px",
        justifyContent: "flex-end",
      }}
    >
      <LoadingButton
        size="small"
        onClick={() => handleSuspend(action)}
        startIcon={<Icon icon="ion:stop-circle-outline" />}
        loading={suspendLoading}
        loadingPosition="end"
        variant="contained"
        color={action === "active" ? "success" : "error"}
        sx={{}}
      >
        {action === "active"
          ? "Activate"
          : action === "suspend"
          ? "Suspend"
          : "In-Activate"}
      </LoadingButton>
      <Button
        variant="outlined"
        size="small"
        onClick={() => {
          setSuspend(false);
          setActive(false);
          setInactive(false);
          handleClose(false);
        }}
      >
        Cancel
      </Button>
    </Box>
  );

  const handleViewDetailsClick = async () => {
    try {
      const { userDetails } = await axiosGetCall("downlineUserDetails", {
        email,
      });
      setUserDetails(userDetails);
      setOpenDetails(true);
    } catch (error) {
      logger.error(error);
    }
  };

  return (
    <Menu
      id="basic-menu"
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      MenuListProps={{
        "aria-labelledby": "basic-button",
      }}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      sx={{ padding: "0px" }}
    >
      <Box
        sx={
          {
            // backgroundColor: 'grey.1000'
          }
        }
      >
        <CustomMenuItem onClick={handleViewDetailsClick}>
          <Icon
            icon="pajamas:details-block"
            width={"17px"}
            style={{ color: theme.palette.primary.main }}
          />
          Details
        </CustomMenuItem>

        <CustomMenuItem onClick={() => setOpenActivity(true)}>
          <Icon
            icon="fe:activity"
            width={"17px"}
            style={{ color: theme.palette.success.main }}
          />
          Activity
        </CustomMenuItem>

        <CustomMenuItem onClick={handleUserDetailsUpdate}>
          <Icon
            icon="clarity:update-line"
            width={"17px"}
            style={{ color: theme.palette.success.main }}
          />
          Update
        </CustomMenuItem>

        {userStatus === "active" && (
          <CustomMenuItem onClick={() => setInactive(true)}>
            <Icon
              icon="material-symbols-light:inactive-order-outline"
              width={"17px"}
              style={{ color: theme.palette.warning.main }}
            />
            In-Activate
          </CustomMenuItem>
        )}

        {(userStatus === "active" || userStatus === "inactive") && (
          <CustomMenuItem onClick={() => setSuspend(true)}>
            <Icon
              icon="ion:stop-circle-outline"
              width={"17px"}
              style={{ color: theme.palette.error.main }}
            />
            Suspend
          </CustomMenuItem>
        )}

        {(userStatus === "suspend" || userStatus === "inactive") && (
          <CustomMenuItem onClick={() => setActive(true)}>
            <Icon
              icon="fontisto:radio-btn-active"
              width={"17px"}
              style={{ color: theme.palette.success.main }}
            />
            Activate
          </CustomMenuItem>
        )}

        {/* <CustomMenuItem onClick={() => setSuspend(true)} >
                    {userStatus === 'suspend' ?
                        <>
                            <Icon icon="fontisto:radio-btn-active" width={"17px"} style={{ color: theme.palette.success.main }} />
                            Active
                        </>
                        :
                        <>
                            <Icon icon="ion:stop-circle-outline" width={"17px"} style={{ color: theme.palette.error.main }} />
                            Suspend
                        </>
                    }

                </CustomMenuItem> */}

        <CustomMenuItem onClick={() => handleRefLogin()}>
          <Icon
            icon="ant-design:login-outlined"
            width={"17px"}
            style={{ color: theme.palette.primary.main }}
          />
          Ref login
        </CustomMenuItem>

        <CustomMenuItem onClick={() => setForceLogout(true)}>
          <Icon
            icon="tabler:logout"
            width={"17px"}
            style={{ color: theme.palette.primary.main }}
          />
          Force logout
        </CustomMenuItem>

        <CustomMenuItem
          onClick={() => {
            setOpenDelete(true);
          }}
        >
          <Icon
            icon="fluent:delete-16-regular"
            width={"15px"}
            style={{ color: theme.palette.error.main }}
          />
          Delete
        </CustomMenuItem>

        <CustomMenuItem
          onClick={(e) => {
            setProductAnchorEl(e.currentTarget);
          }}
        >
          <Icon
            icon="bitcoin-icons:transactions-outline"
            width={"15px"}
            style={{ color: theme.palette.primary.main }}
          />
          Product Actions
        </CustomMenuItem>

        {permission === "l4" && (
          <CustomMenuItem onClick={() => setOpenHierarchy(true)}>
            <Icon
              icon="tabler:hierarchy-3"
              width={"15px"}
              style={{ color: theme.palette.primary.main }}
            />
            Hierarchy
          </CustomMenuItem>
        )}
      </Box>

      <CustomModal
        open={openDelete}
        title={"Delete"}
        description={
          <Box sx={{ mt: 1.3 }}>
            <Typography sx={{ color: "grey.200" }}>
              Are you sure want to delete this user?
            </Typography>
          </Box>
        }
        close={() => setOpenDelete(false)}
        action={deleteActions()}
        isCloseIcon={false}
      />

      <CustomModal
        open={forceLogout}
        title={"Force Logout"}
        description={
          <Box sx={{ mt: 1.3 }}>
            <Typography sx={{ color: "grey.200" }}>
              Are you sure want to logout this user?
            </Typography>
          </Box>
        }
        close={() => setForceLogout(false)}
        action={forceActions()}
        isCloseIcon={false}
      />

      <CustomModal
        open={suspend}
        title={"Suspend User"}
        description={
          <Box
            sx={{
              mt: 1.9,
              display: "flex",
              flexDirection: "column",
              gap: "10px",
            }}
          >
            <Typography sx={{ color: "grey.200" }}>
              Are you sure want to suspend this user?
            </Typography>

            <Box
              sx={{
                mt: 0.3,
                display: "flex",
                flexDirection: "column",
                gap: "5px",
              }}
            >
              <Typography sx={{ color: "grey.200" }}>
                Select supend duration
              </Typography>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  slots={{
                    popper: PickersPopperRoot,
                  }}
                  onChange={handleDDateChange}
                />
              </LocalizationProvider>
            </Box>
          </Box>
        }
        close={() => setSuspend(false)}
        action={suspendActions("suspend")}
        isCloseIcon={false}
      />

      <CustomModal
        open={active}
        title={"Re-activate User"}
        description={
          <Box
            sx={{
              mt: 1.9,
              display: "flex",
              flexDirection: "column",
              gap: "10px",
            }}
          >
            <Typography sx={{ color: "grey.200" }}>
              Are you sure want to re-activate this user?
            </Typography>
          </Box>
        }
        close={() => setActive(false)}
        action={suspendActions("active")}
        isCloseIcon={false}
      />

      <CustomModal
        open={inactive}
        title={"De-activate User"}
        description={
          <Box
            sx={{
              mt: 1.9,
              display: "flex",
              flexDirection: "column",
              gap: "10px",
            }}
          >
            <Typography sx={{ color: "grey.200" }}>
              Are you sure want to In-activate this user?
            </Typography>
          </Box>
        }
        close={() => setInactive(false)}
        action={suspendActions("inactive")}
        isCloseIcon={false}
      />

      <UserDetails
        data={userDetails}
        openDetails={openDetails}
        setOpenDetails={setOpenDetails}
        handleClose={handleClose}
      />

      {openActivity && (
        <ActivityModal
          email={email}
          openActivity={openActivity}
          setOpenActivity={setOpenActivity}
          handleClose={handleClose}
        />
      )}

      <Menu
        id="menu-productActions"
        anchorEl={productAnchorEl}
        open={openProductMenu}
        onClose={handleCloseProductMenu}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        anchorOrigin={{
          vertical: "left",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        sx={{ padding: "0px" }}
      >
        {products?.length
          ? products.map((pName) => (
              <CustomMenuItem
                onClick={() => handleClickProductMenu(pName)}
                key={pName}
              >
                {pName} Actions
              </CustomMenuItem>
            ))
          : "No products found!"}
      </Menu>

      {currentActionProduct === "Broadcast" && (
        <BroadcastActions
          currentActionProduct={currentActionProduct}
          setCurrentActionProduct={setCurrentActionProduct}
          email={email}
        />
      )}

      {currentActionProduct === "Meet" && (
        <MeetActions
          currentActionProduct={currentActionProduct}
          setCurrentActionProduct={setCurrentActionProduct}
          email={email}
          user={data}
        />
      )}

      {openHierarchy && (
        <HierarchyModal
          // data={userDetails}
          email={email}
          openHierarchy={openHierarchy}
          setOpenHierarchy={setOpenHierarchy}
          handleClose={handleClose}
        />
      )}
    </Menu>
  );
};

export default ActionMenu;

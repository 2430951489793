import axios from 'axios';
import { getItem, removeBulkItems } from './cookies';
import { configFile } from "../config";

const { getApiUrl } = configFile;

const token = getItem("sessionToken");

const headers = {
  "session-token": token ?? "",
}
if (window.location.pathname.split("/").length>=3) {
  headers["active-account"] = window.location.pathname.split("/")[2]
}
const axiosIntance = axios.create({
  baseURL: getApiUrl(),
  headers,
});

axiosIntance.interceptors.response.use(function (response) {
  return response;
}, function (error) {
  const status = error?.response?.status;
  if (status === 713) {
    removeBulkItems([{ key: "sessionToken" }, { key: "exp" }, { key: "token" }, {key: "secureToken"}])
    localStorage.removeItem("branding");
    window.location.href = "/login";
  }
  return Promise.reject(error);
});

export default axiosIntance;
import React, { useState } from "react";
import {
  Box,
  Typography,
  Button,
  TextField,
  FormControl,
  MenuItem,
  Select,
  InputAdornment,
  Link,
  styled,
  Divider,
} from "@mui/material";
import { MuiOtpInput } from "mui-one-time-password-input";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import ErrorMessage from "../../../utils/ErrorMessage.json";
import { axiosGetCall } from "../../../services/apisCall";
import { axiosPostCall } from "../../../services/apisCall";
import { setLoginSession } from "../../../utils/common";
import LoadingButton from "@mui/lab/LoadingButton/LoadingButton";
import Logger from "../../../lib/Logger";
import AuthFooter from "../../../layouts/AuthFooter";
import { ToastContainer } from "react-toastify";
import {
  renderErrorMessage,
  renderSuccessMessage,
} from "../../../lib/toastMessage";
import VerifiedIcon from "@mui/icons-material/Verified";
import LogoBlue from "../../../assets/svgs/logo";

const HeadingSection = styled("div")(({ theme }) => ({
  width: "623px",
  display: "flex",
  background: "#FFFFFF",
  padding: "30px 40px",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: "26px",
  [theme.breakpoints.down("sm")]: {
    borderRadius: "0px",
    width: "auto",
    padding: "20px 30px",
  },
}));

function VerificationForm() {
  const location = useLocation();
  const navigate = useNavigate();
  const {
    authentication = false,
    userId = "",
    contact: newContact = "",
    email,
    updateContact = false,
    isMobileVerified = true,
    isEmailVerified = true,
  } = location?.state || {};

  const logger = new Logger("Verification");

  const [mobileOtp, setMobileOtp] = useState();
  const [mobileVerify, setMobileVerify] = useState(isMobileVerified);
  const [emailOtp, setEmailOtp] = useState();
  const [emailVerify, setEmailVerify] = useState(isEmailVerified);
  const [edit, setEdit] = useState(false);
  const [countryCodes, setCountryCodes] = useState([
    { nicename: "India", phonecode: "91" },
  ]);
  const [selectCountryCode, setSelectCountryCode] = useState(91);

  const [contactError, setContactError] = useState(false);

  const [contact, setContact] = useState();
  // const [isResendDisabled, setIsResendDisabled] = useState(true);
  const [updateButtonLoading, setUpdateButtonLoading] = useState(false);
  // const [timer, setTimer] = useState(60);

  // otp verification
  const handleOtpVerification = async (method, value) => {
    if (
      (method === "mobile" && mobileVerify) ||
      (method === "email" && emailVerify)
    )
      return;

    try {
      const payload = { userId, method };
      if (method === "mobile") payload.otp = value;
      if (method === "email") payload.otp = value;

      const { sessionToken, exp, token, workspace } = await axiosPostCall(
        "otpVerification",
        payload
      );
      if (sessionToken && exp && token) {
        setLoginSession(exp, sessionToken, token, workspace.name);
        return (window.location.href = "/");
      }

      if (method === "mobile") setMobileVerify(true);
      if (method === "email") setEmailVerify(true);
    } catch (error) {
      logger.error(error.message);
      renderErrorMessage(error.message);
    }
  };

  /*  useEffect(() => {
     window.addEventListener(
       "popstate",
       (event) => {
         if (event.state) {
           return navigate("/login", { replace: true });
         }
       },
       false
     );
 
     window.addEventListener(
       "beforeunload",
       (event) => {
         return navigate("/login", { replace: true });
       },
       false
     );
   }, [navigate]); */

  // resend otp by sms or call
  const handleOtpSend = async (method) => {
    if (
      (method === "mobile" && mobileVerify) ||
      (method === "email" && emailVerify)
    )
      return;

    try {
      const payload = { userId, mobile: newContact };

      if (method === "email")
        await axiosPostCall("otpSendThroughMail", payload);
      if (method === "mobile")
        await axiosPostCall("otpSendThroughSms", payload);
    } catch (error) {
      logger.error(error.message);
      renderErrorMessage(error.message);
    }
  };

  // Update number
  const updateNumber = async (e) => {
    try {
      e.preventDefault();

      if (!contact) return renderErrorMessage(ErrorMessage?.[4009]);

      setUpdateButtonLoading(true);
      const payload = {
        countryCode: selectCountryCode,
        mobile: contact,
        userId,
      };

      const { message } = await axiosPostCall("updateMobileNumber", payload);

      setUpdateButtonLoading(false);

      navigate("/verification", {
        state: {
          authentication: true,
          userId,
          contact,
          email,
          isMobileVerified,
          isEmailVerified,
        },
        replace: true,
      });

      setEdit(false);
      renderSuccessMessage(message);
    } catch (error) {
      logger.error(error.message);
      setUpdateButtonLoading(false);
      renderErrorMessage(error.message);
    }
  };

  const fetchCountryData = async () => {
    try {
      const { countryCodes = [{ nicename: "India", phonecode: "91" }] } =
        await axiosGetCall("countryCode");
      setCountryCodes(countryCodes);
    } catch (error) {
      logger.error(error.message);
    }
  };

  const handleMobileOtpChange = (newValue) =>
    !mobileVerify && setMobileOtp(newValue);
  const handleEmailOtpChange = (newValue) =>
    !emailVerify && setEmailOtp(newValue);

  useEffect(() => {
    if (edit) fetchCountryData();

    // eslint-disable-next-line
  }, [edit]);

  useEffect(() => {
    if (!authentication) window.location.href = "/login";
    if (updateContact) {
      setEdit(true);
      renderErrorMessage(ErrorMessage?.[4012]);
    }
    setContact(newContact);

    // eslint-disable-next-line
  }, []);

  // useEffect(() => {
  //     let interval;
  //     if (timer > 0 && isResendDisabled) {
  //         interval = setInterval(() => {
  //             setTimer((prevTimer) => prevTimer - 1);
  //         }, 1000);
  //     }
  //     clearInterval(interval);
  //     setIsResendDisabled(false);

  //     return () => clearInterval(interval);
  // }, [timer, isResendDisabled]);

  return (
    <HeadingSection>
      <ToastContainer />
      <Box
        className="FormContent"
        sx={{
          textAlign: "center",
          display: "flex",
          flexDirection: "column",
          gap: "20px",
          width: "100%",
          mb: '40px'
        }}
      >
        <Box sx={{
          display: 'flex',
          justifyContent: 'center',
          mb: {
            xs: '35px',
            sm: '20px',
          }
        }}>
          <Box sx={{
            width: '160px',
            height: '35px',
            display: "flex",
            justifyContent: "center"
          }}>
            <LogoBlue isAuth={true} height={35} />
          </Box>
        </Box>

        <Box
          className="FormGroup"
          sx={{
            display: "flex",
            gap: "20px",
            flexDirection: "column",
          }}
        >
          {!isMobileVerified && (
            <>
              <Box
                className="FormHeading"
                sx={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Typography
                  sx={{
                    display: "flex",
                    color: "#0055CC",
                    justifyContent: "flex-start",
                    fontSize: '30px',
                    fontWeight: 500,
                    gap: "10px",
                  }}
                >
                  Mobile verification
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {mobileVerify && <VerifiedIcon color="success" />}
                  </Box>
                </Typography>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                  gap: "16px",
                }}
              >
                {edit ? (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "25px",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        gap: "5px",
                        flexDirection: "column",
                        width: "100%",
                        alignItems: "flex-start",
                      }}
                    >
                      <Typography
                        variant="body2"
                        sx={{
                          fontSize: "13px",
                          fontWeight: 500,
                          color: "#44546F",
                        }}
                      >
                        Country
                      </Typography>
                      <FormControl variant="standard" fullWidth>
                        <Select
                          labelId="demo-simple-select-standard-label"
                          id="demo-simple-select-standard"
                          value={selectCountryCode}
                          onChange={(e) => setSelectCountryCode(e.target.value)}
                          SelectDisplayProps={{
                            style: {
                              height: "25px",
                              fontSize: "14px",
                              paddingTop: 9,
                              alignItems: "flex-start",
                              justifyContent: "flex-start",
                              display: "flex",
                              color: "#44546F",
                            },
                          }}
                          inputProps={{
                            MenuProps: {
                              PaperProps: {
                                sx: {
                                  maxHeight: "380px",
                                  backgroundColor: "#fff",
                                },
                              },
                            },
                          }}
                        >
                          {countryCodes.length > 0 &&
                            countryCodes.map(({ nicename, phonecode }) => (
                              <MenuItem
                                key={phonecode}
                                value={phonecode}
                                sx={{
                                  width: "300px",
                                  overflowWrap: "break-word",
                                  wordWrap: "break-word",
                                  hyphens: "auto",
                                  color: "#44546F",
                                  backgroundColor: "#ffff ",
                                }}
                              >
                                {nicename} ({phonecode})
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        gap: "5px",
                        flexDirection: "column",
                        width: "100%",
                        alignItems: "flex-start",
                      }}
                    >
                      <Typography
                        variant="body2"
                        sx={{
                          fontSize: "13px",
                          fontWeight: 500,
                          color: "#44546F",
                        }}
                      >
                        Contact No
                      </Typography>
                      <TextField
                        id="ContactNo"
                        placeholder="Contact No"
                        variant="outlined"
                        size="small"
                        fullWidth
                        type={"number"}
                        error={contactError}
                        onClick={() =>
                          contactError ? setContactError(false) : ""
                        }
                        helperText={contactError ? ErrorMessage?.[4003] : ""}
                        onChange={(e) => setContact(e.target.value)}
                        value={contact}
                        sx={{
                          "& .MuiInput-input": {
                            "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button":
                            {
                              "-webkit-appearance": "none",
                            },
                          },
                        }}
                        inputProps={{
                          sx: {
                            height: "30px",
                            fontSize: "14px",
                            color: "#44546F",
                          },
                        }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              {" "}
                              {`+ ${selectCountryCode} `}{" "}
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Box>

                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "flex-end",
                        height: "35px",
                        gap: "10px",
                      }}
                    >
                      <Button
                        variant="contained"
                        // color="secondary"
                        size="small"
                        onClick={() => setEdit(false)}
                        disabled={updateContact}
                      >
                        Back
                      </Button>
                      <LoadingButton
                        loading={updateButtonLoading}
                        variant="contained"
                        color="primary"
                        size="small"
                        onClick={(e) => updateNumber(e)}
                      >
                        Submit
                      </LoadingButton>
                    </Box>
                  </Box>
                ) : (
                  <>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "14px",
                        alignItems: "center",
                        justifyContent: "flex-start",
                        width: "100%",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "8px",
                          width: "100%",
                          justifyContent: 'flex-start',
                          alignItems: 'flex-start'
                        }}
                      >
                        <Typography variant="custom14500" sx={{ color: "#44546F", }}>
                          OTP is sent on <Typography
                            variant="subtitle1"
                            component={"span"}
                            sx={{ color: "#44546F", fontSize: '14px', fontWeight: 600 }}
                          >{`+${selectCountryCode} ${newContact}`}</Typography>
                          <Link
                            variant="subtitle2"
                            underline="always"
                            onClick={() => !isMobileVerified && setEdit(true)}
                            sx={{ cursor: "pointer", color: '#44546F' }}
                          >
                            EDIT
                          </Link>
                        </Typography>
                        {/* <Typography
                          variant="custom14500"
                          sx={{ color: "#8D8D8D" }}
                        >
                          Please enter the verification code sent to
                        </Typography>
                        <Typography
                          variant="subtitle1"
                          sx={{ color: "#282828" }}
                        >{`+${selectCountryCode} ${newContact}`}</Typography> */}
                      </Box>

                    </Box>

                    <Box
                      sx={{
                        display: "flex",
                        gap: "10px",
                        flexDirection: "column",
                        mb: 1,
                        alignItems: "flex-start",
                      }}
                    >
                      <MuiOtpInput
                        value={mobileOtp}
                        onComplete={(value) =>
                          handleOtpVerification("mobile", value)
                        }
                        onChange={handleMobileOtpChange}
                        length={6}
                        validateChar={(val) => !isNaN(val)}
                        autoFocus
                        TextFieldsProps={{ size: 'medium', placeholder: '-' }}
                        sx={{
                          width: '100%',
                          '&.MuiInputBase-input': {
                            color: '#B3B9C4',
                          }
                        }}
                      />
                    </Box>

                    <AuthFooter
                      navigationMessage={"Don't receive code? "}
                      navigateText={"Resend"}
                      navigationCheckHandler={(e) => handleOtpSend("mobile")}
                    />
                  </>
                )}
              </Box>
            </>
          )}

          {!isEmailVerified && !isEmailVerified && !edit && <Divider />}

          {!(isEmailVerified || edit) && (
            <>
              <Box
                className="FormHeading"
                sx={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Typography
                  sx={{
                    display: "flex",
                    color: "#0055CC",
                    justifyContent: "flex-start",
                    fontSize: '30px',
                    fontWeight: 500,
                    gap: "10px",
                  }}
                >
                  Email verification
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {emailVerify && <VerifiedIcon color="success" />}
                  </Box>
                </Typography>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                  gap: "16px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "14px",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "100%",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "8px",
                      width: "100%",
                      justifyContent: 'flex-start',
                      alignItems: 'flex-start'
                    }}
                  >
                    <Typography variant="custom14500" sx={{ color: "#44546F" }}>
                      OTP is sent on <Typography
                        variant="subtitle1"
                        component={"span"}
                        sx={{ color: "#44546F", fontSize: '14px', fontWeight: 600 }}
                      >{`${email}`}</Typography>
                    </Typography>

                  </Box>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    gap: "10px",
                    flexDirection: "column",
                    mb: 1,
                    alignItems: "flex-start",
                  }}
                >
                  <MuiOtpInput
                    value={emailOtp}
                    onComplete={(value) =>
                      handleOtpVerification("email", value)
                    }
                    onChange={handleEmailOtpChange}
                    length={6}
                    validateChar={(val) => !isNaN(val)}
                    TextFieldsProps={{ size: "medium", placeholder: "-" }}
                    sx={{
                      width: '100%',
                      '&.MuiInputBase-input': {
                        color: '#B3B9C4',
                      }
                    }}
                  />
                </Box>

                <AuthFooter
                  navigationMessage={"Don't receive code? "}
                  navigateText={"Resend"}
                  navigationCheckHandler={() => handleOtpSend("email")}
                />
              </Box>
            </>
          )}
        </Box>
      </Box>
    </HeadingSection>
  );
}

export default VerificationForm;

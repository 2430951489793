import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import CustomModal from '../../../components/modal/customModal';
import { getItem, setItem } from '../../../lib/cookies';
import jwtDecode from 'jwt-decode';

import { renderErrorMessage, renderSuccessMessage } from '../../../lib/toastMessage';
// import Logger from '../../../lib/Logger';
import { axiosGetCall, axiosPostCall } from '../../../services/apisCall';
import { Checkbox, FormControlLabel, styled, Switch } from '@mui/material';

import { LoadingButton } from '@mui/lab';
import { decodeToken, getCurrentSessionToken } from '../../../utils/common';

// const logger = new Logger("broadcastActions")

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 28,
    height: 16,
    padding: 0,
    display: 'flex',
    '&:active': {
        '& .MuiSwitch-thumb': {
            width: 15,
        },
        '& .MuiSwitch-switchBase.Mui-checked': {
            transform: 'translateX(9px)',
        },
    },
    '& .MuiSwitch-switchBase': {
        padding: 2,
        '&.Mui-checked': {
            transform: 'translateX(12px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                opacity: 1,
                backgroundColor: theme.palette.mode === 'dark' ? '#177ddc' : '#1890ff',
            },
        },
    },
    '& .MuiSwitch-thumb': {
        boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
        width: 12,
        height: 12,
        borderRadius: 6,
        transition: theme.transitions.create(['width'], {
            duration: 200,
        }),
    },
    '& .MuiSwitch-track': {
        borderRadius: 16 / 2,
        opacity: 1,
        backgroundColor:
            theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : 'rgba(0,0,0,.25)',
        boxSizing: 'border-box',
    },
}));


const MeetActions = ({ currentActionProduct, setCurrentActionProduct, email, user }) => {

    const [value, setValue] = React.useState(0);
    const [callCenterPermisson, setCallCenterPermission] = useState(false);
    const [oldCallCenterPermission, setOldCallCenterPermission] = useState(null);

    const [openMeet, setOpenMeet] = useState(false)

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const fetchMeetCallCenterPermission = async () => {
        try {
            const { userSettings, err } = await axiosGetCall("meetSettings", { email })
            const { contactSetting = false } = userSettings;
            setCallCenterPermission(contactSetting);
            setOldCallCenterPermission(contactSetting);

        } catch (error) {
            console.error("page meetActions.jsx.jsx,func->fetchMeetCallCenterPermission, API->", error);
            renderErrorMessage(error?.message);
            setCurrentActionProduct("")
        }
    }


    const updateCallCenterPermission = async () => {
        try {
            const { name, userId, productPermissions } = user;
            const payload = { name, userId, email, Permission: productPermissions, contactSetting: callCenterPermisson }
            const { message } = await axiosPostCall("addUserSettings", payload)
            renderSuccessMessage(message)
            setCurrentActionProduct("")
        } catch (error) {
            console.error("page meetActions.jsx.jsx,func->updateCallCenterPermission, API->", error);
            renderErrorMessage(error?.message);
            setCurrentActionProduct("")
        }
    }

    const fetchMeetPermission = async () => {
        try {
            const consoleToken = decodeToken();

            const st = {
                "session-token": getCurrentSessionToken(),
            }
            const payload = {
                key: "meet",
                token: consoleToken,
            }

            const { token, reload } = await axiosPostCall("sessionSharing", payload, false, st);

            if (reload) {
                setItem("token", token)
            }

            await fetchMeetCallCenterPermission();
            setOpenMeet(true)

        } catch (error) {
            console.error("page plan.jsx,func->fetchVoiceUserId, API->", error);
            renderErrorMessage(error?.message);
            setCurrentActionProduct("")

        }
    }

    useEffect(() => {
        fetchMeetPermission();
        //eslint-disable-next-line
    }, [])

    const description = (

        <Box sx={{ width: '100%', mt: '10px' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                    <Tab label="Aditional Settings" {...a11yProps(0)} />

                </Tabs>
            </Box>
            <CustomTabPanel value={value} index={0}>
                <Box sx={{ width: '100%', mt: '10px', display: "flex", flexDirection: "column" }}>
                    <FormControlLabel control={<Checkbox checked={callCenterPermisson} onChange={(e) => setCallCenterPermission(e.target.checked)} />} label="Allow Call Center" />
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: '10px' }}>
                        <LoadingButton
                            variant="contained"
                            sx={{ width: "100px" }}
                            disabled={callCenterPermisson === oldCallCenterPermission}
                            onClick={updateCallCenterPermission}>Submit</LoadingButton>
                    </Box>
                </Box>
            </CustomTabPanel>
            {/* <CustomTabPanel value={value} index={1}>

            </CustomTabPanel> */}
        </Box>
    )
    return (
        openMeet && <CustomModal
            open={currentActionProduct === "Meet"}
            title={"Meet Actions"}
            description={description}
            close={() => setCurrentActionProduct("")}
            // action={forceActions()}
            isCloseIcon={true}
        />
    )
}

export default MeetActions;


// All apis base url
export function getApiUrl() {
  return `https://dev-console.sarv.com/console-api/`;
  // return `https://${window.location.hostname}/console-api/`;
}

export function getVBApiUrl() {
  // return `https://${window.location.hostname}/vb-api/`;;
  return `https://dev-console.sarv.com/vb-api/`;
}

// Get api endpoints dynamically
export function endPoints(title) {
  const version = "v1/";
  const routes = {
    countryCode: `${version}mis/country_code`,
    login: `${version}auth/login`,
    otpSendThroughSms: `${version}auth/otpSenderThroughSms`,
    otpSendThroughCall: `${version}auth/otpSendThroughCall`,
    otpSendThroughMail: `${version}auth/otpSendThroughMail`,
    updateMobileNumber: `${version}auth/updateMobileNumber`,
    otpVerification: `${version}auth/verifyOtp`,
    register: `${version}auth/register`,
    resetPassword: `${version}auth/resetPassword`,
    forgotPassword: `${version}auth/forgotPassword`,
    otpVerificationThroughEmailCode: `${version}auth/otpverificationthroughemailCode`,
    sessionSharing: `${version}mis/shareSessionToken`,
    logout: `${version}auth/logout`,
    uploadKyc: `${version}kyc/uploadKyc`,
    consoleDetails: `${version}dashboard/consoleDetails`,
    kycStatus: `${version}kyc/kycStatus`,
    reUploadKyc: `${version}kyc/reUploadKyc`,
    adDetails: `${version}mis/advertisement`,
    generateOtp: `${version}kyc/generateOtp`,
    verifyOtp: `${version}kyc/verifyOtp`,
    leads: `${version}mis/leads`,
    usersList: `${version}user/usersList`,
    fetchPassword: `${version}user/userPassword`,
    fetchBranding: `${version}branding/brandingDetails`,
    updateBrandingDetails: `${version}branding/updateBrandingDetails`,
    createBranding: `${version}branding/createBranding`,
    refLogin: `${version}auth/consoleReferenceLogin`,
    createUser: `${version}user/downlineUser`,
    updateUser: `${version}user/updateDownlineUser`,
    deleteUser: `${version}user/deleteUser`,
    suspendUser: `${version}user/suspendUser`,
    forceLogout: `${version}user/forceLogoutUser`,
    updateBranding: `${version}branding/updateBranding`,
    downlineUserDetails: `${version}user/downlineUserDetails`,
    userActivity: `${version}user/userActivity`,
    createOrder: `${version}payment/createOrder`,
    verifyPayment: `${version}payment/verify`,
    fetchWalletBalance: `${version}payment/fetchWalletBalance`,
    getTransactionsList: `${version}payment/getTransactionsList`,
    getPaymentInvoiceList: `${version}payment/getPaymentInvoiceList`,
    assignPlan: `${version}plan/assignPlans`,
    getHierarchy: `${version}user/getHierarchy`,
    meetSettings: `${version}meetSettings/userSettings`,
    addUserSettings: `${version}meetSettings/addUserSettings`,
    employeeList: `${version}user/EmployeeList`,
    inviteEmployee: `${version}user/inviteEmployee`,
    inviteResponse: `${version}auth/inviteResponse`,
    planDetails: `${version}plan/fetchPlanDetails`,
    fetchPlans: `${version}plan/fetchPlans`,
    createWorkspace: `${version}workspace/createWorkspace`,
    fetchWorkspace: `${version}workspace/fetchWorkspace`,
    createWorkspaceFolder: `${version}workspace/createWorkspaceFolder`,
    assignedPlanDetails: `${version}plan/fetchAssignedPlans`,
    getSlabs: `${version}plan/fetchPlanSlabs`,
    fetchWorkspaceFolder: `${version}workspace/fetchWorkspaceFolder`,
    workspaceProgress: `${version}workspace/progress`,
    setupEmailServer: `${version}workspace/setup-email-server`,
    addDomain: `${version}workspace/addDomain`,
    generateVerificationTXT: `${version}workspace/generateVerificationTXT`,
    txtRecordVerificaion: `${version}workspace/txtRecordVerificaion`,
    generatedkimRecords: `${version}workspace/generatedkimRecords`,
    verifyMXDkimRecords: `${version}workspace/verifyMX-DkimRecords`,
    switchAccount: `${version}auth/switchAccount`, 
    
  };

  const baseUrl = getApiUrl();
  const routeEndPoint = Object.entries(routes).find(
    (item) => item[0] === title
  );
  return `${baseUrl}${routeEndPoint[1]}`;
}

export function VBEndpoint(title) {
  const version = "v2/";
  const routes = {
    login: `${version}auth/login`,
    otpVerification: `${version}auth/otpVerification`,
    otpResend: `${version}auth/otpResend`,
    logout: `${version}auth/logout`,
    creationDetials: `${version}campaigns/campaignCreationDetails`,
    getCampaignList: `${version}campaigns/getCampaignList`,
    getCampaignDetails: `${version}campaigns/getCampaignDetails`,
    dashboard: `${version}campaigns/dashboard`,
    createCampaign: `${version}campaigns/createCampaign`,
    getCallList: `${version}campaigns/getCallList`,
    totalCampaigns: `${version}campaigns/totalCampaigns`,
    resellerDetails: `${version}campaigns/resellerDetails`,
    createBranding: `${version}branding/createBranding`,
    brandingDetails: `${version}branding/brandingDetails`,
    updateBrandingDetails: `${version}branding/updateBrandingDetails`,
    updateBranding: `${version}branding/updateBranding`,
    contactCsvDownload: `${version}campaigns/downloadCampaignDetailCsv`,
    creditDetails: `${version}credit/getCredit`,
    userList: `${version}user/userList`,
    assignCreditToUser: `${version}credit/assignCreditToUser`,
    removeUserPlan: `${version}credit/removeUserPlan`,
    assignPlanToUser: `${version}credit/assignPlanToUser`,
    getPlanNCredits: `${version}credit/getPlanNCredits`,
    uploadAnnouncement: `${version}announcement/uploadAnnouncement`,
    createUser: `${version}user/createUser`,
    announcementList: `${version}announcement/announcementList`,
    downloadCampaignKeyPressCsv: `${version}campaigns/downloadCampaignKeyPressCsv`,
    changeCampaignStatus: `${version}campaigns/changeCampaignStatus`,
    deleteAnnouncement: `${version}announcement/deleteAnnouncement`,
    getCmpCSVDownloadRequestList: `${version}campaigns/getCmpCSVDownloadRequestList`,
    assignTTSCreditToUser: `${version}credit/assignTTSCreditToUser`,
    getCmpCsvDownloadLink: `${version}campaigns/getCmpCsvDownloadLink`,
    getCreditInvoices: `${version}credit/getCreditInvoices`,
    refLogin: `${version}auth/refLogin`,
    updateProfileSetting: `${version}settings/profile`,
    tokenGenerate: `${version}settings/token`,
    tts: `${version}tts`,
    getUserSettingsInfo: `${version}user/getUserSettingsInfo`,
    createIvr: `${version}ivr/createIvrFlow`,
    updateIvr: `${version}ivr/updateIvrFlow`,
    ivrAnnouncementList: `${version}announcement/getUserAllAnnouncements`,
    getTemplate: `${version}cdr/template`,
    updateTemplate: `${version}cdr/template`,
    getUserPassword: `${version}user/getUserPassword`,
    ivrList: `${version}ivr`,
    getUserCampaignInvoiceList: `${version}campaignInvoice/getUserCampaignInvoiceList`,
    getBlacklistNumbersList: `${version}settings/getBlacklistNumbersList`,
    updatebBlacklist: `${version}settings/blacklist`,
    getWebhookTemplate: `${version}webhook/getWebhookTemplate`,
    getUserIdAndEmail: `${version}mis/getUserIdAndEmail`,
  };

  const baseUrl = getVBApiUrl();
  const routeEndPoint = Object.entries(routes).find(
    (item) => item[0] === title
  );
  return `${baseUrl}${routeEndPoint[1]}`;
}
//for adding new product
export const URL_MAPPER = {
  webinar: `https://${window.location.hostname}/webinar`,
  meet: `https://${window.location.hostname}/meet`,
  console: `https://${window.location.hostname}`,
  sms: `https://${window.location.hostname}/sms`,
  voice: `https://${window.location.hostname}/vb`,
  chat: `https://${window.location.hostname}/chat`,
  lists: `https://${window.location.hostname}/lists`,
  drive: `https://${window.location.hostname}/drive`,
  email: `https://${window.location.hostname}/mail`,
  wa: `https://${window.location.hostname}/wa`,
  calendar: `https://${window.location.hostname}/calendar`,
  contacts: `https://${window.location.hostname}/contacts`,
};
export const GOOGLE_AUTH_CLIENT_ID =
  "953073211267-0fgof26874r31npfsdfrjiku23pngarm.apps.googleusercontent.com";

export const razorpayConfig = {
  checkoutUrl: 'https://checkout.razorpay.com/v1/checkout.js',
  key: 'rzp_test_uD3HnYCnTX1EfZ'
}

// export const razorpayConfig = {
//   checkoutUrl: 'https://checkout.razorpay.com/v1/checkout.js',
//   key: 'rzp_live_AVzGdROKiUFcqg'
// }
export const RECAPTCHA_SITE_API_KEY =
  "6Lc37cIpAAAAAEoFRYYpGjy0HRDA9G51GcdOtfyd";

import React, { useEffect } from "react";
import { Link, Navigate, Outlet, Route, useNavigate } from "react-router-dom";

//pages
import Dashboard from "../pages/Dashboard";
import Sidebar from "../layouts/Sidebar";
import Login from "../pages/Auth/Login";
import SignUp from "../pages/Auth/Signup";
import ForgotPwd from "../pages/Auth/ForgotPwd";
import Verification from "../pages/Auth/Verification";
import { ProtectedRoute, PublicRoute } from "./hoc";
import Spinner from "../pages/spinner";
import Navbar from "../layouts/Navbar";
import Settings from "../pages/settings";
import ComingSoon from "../pages/comingSoon";
import CustomSession from "../pages/customSession";
import { allowedRoute, getCurrentPath } from "../utils/common";
import Chat from "../pages/chat";
import Privacy from "../pages/privacy";
import UsersList from "../pages/usersList";
import NewUsers from "../pages/newUsers";
import Employeelist from "../pages/employeelist";
import Branding from "../pages/branding";
import ReferenceLogin from "../pages/referenceLogin";
import Plans from "../pages/plans";
import Contacts from "../pages/contactUs";
import Checkout from "../pages/Orders/order";
import TypeAccount from "../pages/Auth/Gsuite/Type";

import CreateWorkspace from "../sections/users/createWorkspace";
import CreateDomain from "../sections/users/createDomain";
import Invitation from "../pages/Invitation";
import Logout from "../pages/logout";
import MyPlans from "../pages/myPlans";
import WalletTabPanel from "../pages/paymentInvoice";
import { useSelector } from "react-redux";

function RedirectToGoogle() {

    useEffect(() => {
        window.location.href = getCurrentPath();
    }, []);

    return null;
}

export default function AuthRouter() {
    const workspaceDetails = useSelector(
        (state) => state.workspace.workspaceDetails
    );
    return (
        <>
            <Route
                element={
                    <>
                        <ProtectedRoute>
                            <Outlet />
                        </ProtectedRoute>
                    </>
                }
            >
                <Route
                    path="/"
                    element={
                        <>
                            <Sidebar /> <Navbar /> <Outlet />{" "}
                        </>
                    }
                >
                    {/* <Route path="/" element={<Navigate to={`/u/${0}`} replace />} /> */}
                    <Route path="/" element={<RedirectToGoogle/>} />
                </Route>
            </Route >
            <Route
                element={
                    <>
                        <PublicRoute>
                            {" "}
                            <Outlet />{" "}
                        </PublicRoute>{" "}
                    </>
                }
            >
                {allowedRoute("signUp") && (
                    <Route path="/register" element={<SignUp />} />
                )}
                {allowedRoute("forgetPassword") && (
                    <Route path="/forget-password" element={<ForgotPwd />} />
                )}
                {allowedRoute("TypeAccountForm") && (
                    <Route path="/Type-Account-Form" element={<TypeAccount />} />
                )}

                {allowedRoute("invitation") && (
                    <Route path="/invitation" element={<Invitation />} />
                )}

                <Route path="/verification" element={<Verification />} />
            </Route>
            {/* <Route exact path="/spinner" element={<Spinner />} /> */}
            <Route path="/login" element={<Login />} />
            <Route exact path="/logout" element={<Logout />} />
        </>
    );
}

import { styled, Typography, Box, Grid, Divider } from '@mui/material';
import React from 'react';
import samsungSvg from './brandingCompSvgs/samsung.svg';
import dbCorpLtd from './brandingCompSvgs/samsung.svg';
import housing from './brandingCompSvgs/housing.svg';
import incomeTax from './brandingCompSvgs/incomeTax.svg';
import mahindraDefence from './brandingCompSvgs/mahindraDefence.svg';
import routeMobile from './brandingCompSvgs/routeMobile.svg';
import tanla from './brandingCompSvgs/tanla.svg';
import up112 from './brandingCompSvgs/up112.svg';
import invitedComma from './brandingCompSvgs/invitedComma.svg';
import mcl from './brandingCompSvgs/mcl.svg';
import lalit from './brandingCompSvgs/lalit.svg';
import narayna from './brandingCompSvgs/narayna.svg';

import { Carousel } from 'react-responsive-carousel';

const HeadingSection = styled("div")(({ theme }) => ({
    width: "622px",
    display: "flex",
    background: "linear-gradient(266.4deg, #E9F2FF 2.79%, #FFF3EB 100%)",
    padding: "40px 60px",
    // alignItems: "center",
    justifyContent: "center",
    borderTopLeftRadius: "26px",
    borderBottomLeftRadius: "26px",
    flexDirection: 'column',
    [theme.breakpoints.down("sm")]: {
        borderRadius: "0px",
        width: window.innerWidth,
        padding: "20px 30px",
    },
}));


const tiles = [{
    title: "Samsung",
    url: samsungSvg
},
{
    title: "Route Mobile",
    url: routeMobile
},
{
    title: "UP 112",
    url: up112
},
{
    title: "Income Tax",
    url: incomeTax
},
{
    title: "Housing.com",
    url: housing
},
{
    title: "Mahindra",
    url: mahindraDefence
},
{
    title: "Tanla",
    url: tanla
},
{
    title: "DB Corp",
    url: dbCorpLtd
}
]

const textSx = {
    fontSize: '36px',
    fontWeight: 500,
    background: "linear-gradient(87.59deg, #0052CC 31.92%, #EC6F2B 75.48%)",
    "-webkit-text-fill-color": 'transparent',
    "-webkit-background-clip": 'text',
    textAlign: 'start'
};

const CursolerCard = ({ message, logo, name, role }) => {
    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'row',
        }}>
            <img src={invitedComma} alt="comma" style={{ maxWidth: '38px' }} height={28} />

            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '10px',
                ml: '8px',
                mt: '10px'
            }}>
                <Typography sx={{
                    fontSize: '14px',
                    fontWeight: 300,
                    color: '#000000',
                    textAlign: 'start',
                }}>
                    {message}
                </Typography>

                <Box sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    width: '100%',
                    gap: '10px'
                }}>
                    <img src={logo} alt='logo' style={{ maxWidth: '100px' }} height={27} />

                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%'
                    }}>

                        <Typography sx={{
                            fontSize: '12px',
                            fontWeight: 600,
                            color: '#44546F',
                            textAlign: 'start',
                        }}>
                            {name}
                        </Typography>
                        <Typography sx={{
                            fontSize: '12px',
                            fontWeight: 400,
                            color: '#44546F',
                            textAlign: 'start',
                        }}>
                            {role}
                        </Typography>
                    </Box>

                </Box>
            </Box>

        </Box>
    )
}

const renderCustomIndicator = (isSelected, index) => {
    const bgcolor = isSelected ? "#FEA362" : "#B3B9C4"
    if (isSelected) {
        return (<Box sx={{
            display: 'flex',
            flexDirection: 'row',
            gap: '5px',
            alignItems: 'center',
            justifyContent: 'flex-end',
            mr: index === 0 ? '68px' : index === 1 ? '34px' : '0px',
            mb: index === 0 ? '-2px' : index === 1 ? '-2px' : '0px',
        }}>
            <Divider sx={{ width: '25px', bgcolor, height: '2px' }} />
        </Box>
        )
    }

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'row',
            gap: '5px',
            alignItems: 'center',
            justifyContent: 'flex-end',
            mr: index === 0 ? '68px' : index === 1 ? '34px' : '0px',
            mb: index === 0 ? '-2px' : index === 1 ? '-2px' : '0px',
        }}>
            <Divider sx={{ width: '25px', bgcolor, height: '2px' }} />
        </Box>

    )
}

const SliderNBranding = ({ isSinup }) => {
    return (
        <HeadingSection sx={{ gap: '87px' }}>
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '2px'
            }}>
                <Typography sx={{
                    fontSize: '36px',
                    fontWeight: 500,
                    color: '#1C2B41',
                    mb: '-10px'
                }}>
                    One platform to
                </Typography>

                <Carousel
                    autoPlay={true}
                    infiniteLoop={true}
                    interval={3000}
                    showArrows={false}
                    showStatus={false}
                    showIndicators={false}
                    showThumbs={false}
                    transitionTime={1000}
                    verticalSwipe="standard"
                    axis="vertical"
                >

                    <Typography sx={textSx}>
                        Streamline all workflows
                    </Typography>

                    <Typography sx={textSx}>
                        Collaborate with teams
                    </Typography>

                    <Typography sx={textSx}>
                        Host webinars seamlessly
                    </Typography>
                </Carousel>


                <Typography sx={{
                    fontSize: '36px',
                    fontWeight: 500,
                    color: '#1C2B41',
                    mt: '-10px'
                }}>
                    anywhere, anytime
                </Typography>
            </Box>

            {isSinup && <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '16px'
            }}>

                <Box sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    width: '100%',
                    alignItems: 'center'
                }}>

                    <Typography sx={{
                        fontSize: '12px',
                        fontWeight: 600,
                        color: '#626F86',
                        textTransform: 'uppercase',
                        width: '200px'
                    }}>
                        Customer Stories
                    </Typography>
                    <Divider sx={{ width: 'calc(100% - 154px)' }} />
                </Box>

                <Carousel
                    autoPlay={true}
                    infiniteLoop={true}
                    interval={3000}
                    showArrows={false}
                    showStatus={false}
                    showThumbs={false}
                    transitionTime={1000}
                    dynamicHeight={false}
                    renderIndicator={(onClickHandler, isSelected, index, label) => renderCustomIndicator(isSelected, index)}
                >
                    <CursolerCard
                        message={"Great services by Sarv. They nicely explained plans suitable for our company. Sarv was quick to provide revised negotiated prices offer and also made a good pafefitch for other services offered by Sarv and we are considering buying more services from Sarv. Hope to have a long relationship with Sarv."}
                        logo={mcl}
                        name={"Bheem Singh Akhawat"}
                        role={"Deputy Manager"}
                    />
                    <CursolerCard
                        message={"Sarv provided a very user-friendly SMS portal which helps us in our business, I am very impressed with the SMS API which on a daily basis does lots of tasks automatically."}
                        logo={lalit}
                        name={"The Lalit Hotels"}
                        role={"www.thelalit.com"}
                    />
                    <CursolerCard
                        message={"With the world-class toll free number features, we are now able to keep track of each call and it helps in our business very well. Thanks to Sarv.com and its team."}
                        logo={narayna}
                        name={"Dr. Sindhura Narayana"}
                        role={"Managing Director"}
                    />
                </Carousel>

            </Box>
            }

            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '16px'
            }}>

                <Box sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    width: '100%',
                    alignItems: 'center'
                }}>

                    <Typography sx={{
                        fontSize: '12px',
                        fontWeight: 600,
                        color: '#626F86',
                        textTransform: 'uppercase',
                        width: '200px'
                    }}>
                        Join 50,000 companies
                    </Typography>
                    <Divider sx={{ width: 'calc(100% - 154px)' }} />
                </Box>

                <Grid
                    container
                    spacing={4}
                    justifyContent="center"
                >
                    {tiles.map(({ url, title }, index) => (
                        <Grid
                            item
                            key={index}
                            xs={6}
                            sm={6}
                            md={3}
                            // spacing={}
                            style={{
                                minWidth: 100,
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                height: 'auto',
                            }}
                        >
                            <img src={url} alt={title} />
                            {/* {url} */}
                        </Grid>
                    ))}
                </Grid>
            </Box>

        </HeadingSection >
    )
}

export default SliderNBranding
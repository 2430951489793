import jsCookie from "js-cookie";
import PropTypes from "prop-types";
import { checkIframe } from "../utils/common";

// Get Single cookie data.
// const getItem = (key) => {

  // if (checkIframe()) {
  //   const refData = jsCookie.get(key);
  //   const data = JSON.parse(refData);
  //   return data[key];
  // }
//   return jsCookie.get(key);
// };

const getItem = (key) => {
  const secureField = ["exp", "sessionToken", "token", "workspace"]
  if(secureField.includes(key)) {
    const currentPath = window.location.pathname.split("/");
    const isIframe = checkIframe();
    const secureToken = isIframe ? jsCookie.get("refSecureToken") : jsCookie.get("secureToken");
    // debugger;
    if (secureToken) {
      const parsedToken = JSON.parse(secureToken);
      if (!isIframe && currentPath && currentPath.length >= 3) {
        const result = parsedToken[currentPath[2]]?.[key];
        return result;
      } else {
        const result = parsedToken[0]?.[key];
        return result;
      }
    }
    else {
      return jsCookie.get(key);
    }
  } else {
    return jsCookie.get(key);
  }
}

getItem.propTypes = {
  key: PropTypes.string.isRequired, // String type key requried
};

// Get multiple cookees data.
const getBulkItems = (keyNamesArray) => {
  // console.log("Inside bulk Item", keyNamesArray);
  return keyNamesArray.map((key) => {
    const value = getItem(key);
    const planeKey = key.split("-")
    return { [planeKey.length > 0 ? planeKey[0] : key]: value };
  });
};

getBulkItems.propTypes = {
  items: PropTypes.arrayOf(PropTypes.string).isRequired,
  // required array formate which is array of strings i.e ["key1", "key2", ...]
};

// Set single cookie.
// const setItem = (key, value, exp = 1, path = "/") => {
//   return jsCookie.set(key, value, {
//     expires: exp,
//     path,
//     sameSite: "none",
//     secure: true,
//   });
// };

const setItem = (key, value, exp = 1, path = "/") => {
  const secureField = ["exp", "sessionToken", "token", "workspace"];

  if (secureField.includes(key)) {
    const currentPath = window.location.pathname.split("/");
    const isIframe = checkIframe();
    const secureToken = isIframe ? jsCookie.get("refSecureToken") : jsCookie.get("secureToken");
    let parsedToken = secureToken ? JSON.parse(secureToken) : {};

    if (!isIframe && currentPath && currentPath.length >= 3) {
      const pathKey = currentPath[2];
      parsedToken[pathKey] = parsedToken[pathKey] || {};
      parsedToken[pathKey][key] = value;
    } else {
      parsedToken[0] = parsedToken[0] || {};
      parsedToken[0][key] = value;
    }

    const tokenKey = isIframe ? "refSecureToken" : "secureToken";
    return jsCookie.set(tokenKey, JSON.stringify(parsedToken), {
      expires: exp,
      path,
      sameSite: "none",
      secure: true,
    });
  } else {
    return jsCookie.set(key, value, {
      expires: exp,
      path,
      sameSite: "none",
      secure: true,
    });
  }
};


setItem.propTypes = {
  key: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  exp: PropTypes.number,
  path: PropTypes.string,
  // required array formate which is array of strings i.e ["key1", "key2", ...]
};

// Set multiple cookies.
const setBulkItems = (keyValArrayOfObjects = []) => {
  return keyValArrayOfObjects.map(({ key, value, exp = 1, path = "/" }) =>
    setItem(key, value, exp, path)
  );
};

setBulkItems.propTypes = {
  keyValArrayOfObjects: PropTypes.arrayOf(PropTypes.object).isRequired,
};

// Remove particular single cookie.
const removeItem = (key, path = "/") => {
  if (checkIframe()) {
    return jsCookie.remove("refSecureToken", { path })
  }
  return jsCookie.remove(key, { path });
};
removeItem.propTypes = {
  key: PropTypes.string.isRequired,
  path: PropTypes.string,
};

// Remove miltiple cookies.
const removeBulkItems = (keyValArrayOfObjects = []) => {
  return keyValArrayOfObjects.map(({ key, path = "/" }) =>
    removeItem(key, path)
  );
};
removeBulkItems.propTypes = {
  keyValArrayOfObjects: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export {
  getItem,
  getBulkItems,
  setItem,
  setBulkItems,
  removeItem,
  removeBulkItems,
};

import React from "react";
import Grid from '@mui/material/Unstable_Grid2';
import ForgotPwdForm from "../../../components/Auth/ForgotPwd";
import { styled } from "@mui/material";
// import { imagesUrl } from "../../../config/commonConfig";


const MainSection = styled(Grid)(({ theme }) => ({
    [theme.breakpoints.down('sm')]: {
        alignItems: 'flex-start',
        width: '100%',
        height: '100lvh'
    }
}));

export default function ForgotPwd() {
    return (
        <MainSection sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
            background: {
                xs: '#fff',
                sm: '#F7F8F9'
                // sm: `#0F478D 0px -64.059px / 100% 115.26% url(${imagesUrl?.authBackground})`,
            },
            // backgroundBlendMode: 'multiply',
            // backgroundRepeat: "repeat"
            // linearGradient: '(0deg,  #F7F8F9,  #F7F8F9)'


        }}>
            {/* SignIn Component */}
            <ForgotPwdForm />
        </MainSection>
    )
}

import React from "react";
import Grid from "@mui/material/Unstable_Grid2";
import VerificationForm from "../../../components/Auth/Verification";

export default function Verification() {
  return (
    <Grid
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
        background: {
          xs: '#fff',
          sm: `#F7F8F9`,
        },
        // backgroundBlendMode: 'multiply',
        // backgroundRepeat: "repeat"
      }}
    >
      <VerificationForm />
    </Grid>
  );
}

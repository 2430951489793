// import PropTypes from 'prop-types';
import * as React from "react";

import { Box, IconButton, useTheme, Skeleton } from "@mui/material";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import { Icon } from "@iconify/react";
import LoadingButton from "@mui/lab/LoadingButton";

import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";

import { styled } from "@mui/material/styles";
import ActionMenu from "./actionMenu";
import { copyToClipBoard, dateFormate, nameFormater } from "../../utils/common";
import { axiosGetCall } from "../../services/apisCall";
import {
  renderErrorMessage,
  renderSuccessMessage,
} from "../../lib/toastMessage";
import CircularProgressWithLabel from "./circleProgressBar";
// import CircularProgressWithLabel from '../../components/progressBar/circleProgressBar';

const CustomTableCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: theme.palette.grey[300],
  color: theme.palette.grey[900],
  "&:hover": {
    color: theme.palette.grey[1000],
  },
}));

const Usercolumns = [
  { id: "name", label: "Name", minWidth: 170 },
  { id: "userName", label: "Username", minWidth: 100 },
  { id: "userId", label: "UserID", minWidth: 100 },
  { id: "mobile", label: "Mobile", minWidth: 100 },
  { id: "email", label: "Email", minWidth: 100 },
  { id: "password", label: "Password", minWidth: 100, align: "center" },
  // { id: 'origin', label: 'Origin', minWidth: 100 },
  { id: "userStatus", label: "Status", minWidth: 100, align: "center" },
  { id: "createdAt", label: "Created At", minWidth: 100, align: "center" },
  { id: "isGoogleLogin", label: "G Login", minWidth: 100, align: "center" },
  // { id: 'verifiedForcefully', label: 'Verification Type', minWidth: 100, align: 'center' },
  { id: "kycStatus", label: "Kyc Status", minWidth: 100, align: "center" },
  { id: "permission", label: "Permissions", minWidth: 100, align: "center" },
  { id: "actions", label: "Actions", minWidth: 20, align: "center" },
];
const Employeecolumns = [
  { id: "name", label: "Name", minWidth: 170 },
  { id: "userName", label: "Username", minWidth: 100 },
  { id: "userId", label: "UserID", minWidth: 100 },
  { id: "mobile", label: "Mobile", minWidth: 100 },
  { id: "email", label: "Email", minWidth: 100 },
  { id: "password", label: "Password", minWidth: 100, align: "center" },
  // { id: 'origin', label: 'Origin', minWidth: 100 },
  { id: "userStatus", label: "Status", minWidth: 100, align: "center" },
  { id: "createdAt", label: "Created At", minWidth: 100, align: "center" },
  { id: "isGoogleLogin", label: "G Login", minWidth: 100, align: "center" },
  // { id: 'verifiedForcefully', label: 'Verification Type', minWidth: 100, align: 'center' },
  { id: "kycStatus", label: "Kyc Status", minWidth: 100, align: "center" },
  { id: "folder", label: "Group", minWidth: 100, align: "center" },
  { id: "workspace", label: "Workspace", minWidth: 100, align: "center" },
  { id: "permission", label: "Permissions", minWidth: 100, align: "center" },
  { id: "actions", label: "Actions", minWidth: 20, align: "center" },
];

export default function ListTable({
  usersList,
  count,
  isListLoading,
  fetchUsersList,
  page,
  setPage,
  rowsPerPage,
  setRowsPerPage,
  setLoad,
  calcSize = 201,
  type,
}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [password, setPassword] = React.useState("");
  const [passwordLoading, setPasswordLoading] = React.useState(false);
  const [passwordEmail, setPasswordEmail] = React.useState("");
  const [passwordTimer, setPasswordTimer] = React.useState(100);
  const [passwordInterval, setPasswordInterval] = React.useState("");
  let columns = type === "user" ? Usercolumns : Employeecolumns;
  const [actionData, setActionData] = React.useState("");
  const open = Boolean(anchorEl);
  const theme = useTheme();

  const handleClick = (event, email) => {
    setActionData(email);
    setAnchorEl(event.currentTarget);
  };

  const handleCloseActions = () => {
    setAnchorEl(null);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setLoad(true);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
    setLoad(true);
  };

  const fetchPassword = async (email) => {
    try {
      setPasswordTimer(100);
      setPasswordEmail(email);
      setPasswordLoading(true);
      const { userPassword } = await axiosGetCall("fetchPassword", { email });
      setPassword(userPassword);
      copyToClipBoard(userPassword);
      renderSuccessMessage("Password copied to clipboard.");

      const interval = setInterval(() => {
        //     setPasswordTimer((prevProgress) => (prevProgress >= 100 ? 0 : prevProgress + 10));
        setPasswordTimer((prevProgress) =>
          prevProgress <= 0 ? 0 : prevProgress - 10
        );
        // }, 1000);
      }, 1000);

      setPasswordInterval(interval);
    } catch (error) {
      console.log(error);
      renderErrorMessage(error?.message || "Network error!");
    } finally {
      setPasswordLoading(false);
    }
  };

  const statusChip = (id, align, status) => {
    return (
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          gap: "7px",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {status && (
          <Box
            sx={{
              width: "7px",
              height: "7px",
              backgroundColor:
                status === "active"
                  ? "success.main"
                  : status === "inactive"
                    ? "warning.main"
                    : "error.main",
              borderRadius: "50%",
            }}
          ></Box>
        )}

        <Typography>{nameFormater(status)}</Typography>
      </Box>
    );
  };

  const passwordChip = (id, alige, isPassword, row) => {
    return isPassword ? (
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          gap: "7px",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {password && passwordEmail === row?.email && <>{password}</>}

        <Tooltip
          title={
            password && passwordEmail === row?.email
              ? "Password Copied!"
              : "Copy Password"
          }
          componentsProps={{
            tooltip: {
              sx: {
                bgcolor: "grey.700",
              },
            },
          }}
        >
          <LoadingButton
            loading={passwordEmail === row?.email ? passwordLoading : false}
            loadingPosition="start"
            size={"small"}
            color={
              passwordEmail === row?.email && password ? "success" : "primary"
            }
            sx={{
              borderRadius: "50%",
              minWidth: "auto",
            }}
            onClick={() => fetchPassword(row?.email)}
          >
            {!(passwordEmail === row?.email && passwordLoading) && (
              <Icon icon="prime:copy" width={"20px"} />
            )}
          </LoadingButton>
        </Tooltip>

        {/* {passwordEmail === row?.email && password &&
                    <Tooltip title={`For security purpose password will automaticaly hide in ${passwordTimer / 10} `} componentsProps={{
                        tooltip: {
                            sx: {
                                bgcolor: 'grey.700',
                            },
                        },
                    }}>
                        <CircularProgressWithLabel value={setPasswordTimer} />
                    </Tooltip>} */}
        {passwordEmail === row?.email && password && (
          <CircularProgressWithLabel value={passwordTimer} />
        )}
      </Box>
    ) : (
      <>--</>
    );
  };

  const gLoginChip = (id, align, value) => {
    return value ? (
      <Tooltip
        title={"Signed up with google"}
        componentsProps={{
          tooltip: {
            sx: {
              bgcolor: "grey.300",
            },
          },
        }}
      >
        <Icon
          icon="heroicons-solid:shield-check"
          color={theme.palette.primary.main}
          width={"20px"}
        />
      </Tooltip>
    ) : (
      "--"
    );
  };
  const mobileChip = (id, align, value, data) => {
    return value ? (
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          gap: "7px",
          alignItems: "center",
          justifyContent: "flex-start",
        }}
      >
        <Tooltip
          title={"Check to copy"}
          componentsProps={{
            tooltip: {
              sx: {
                bgcolor: "grey.700",
              },
            },
          }}
        >
          <Typography
            variant="inherit"
            onClick={() => {
              copyToClipBoard(value);
              renderSuccessMessage("Mobile number copied!");
            }}
          >
            {value}
          </Typography>
        </Tooltip>
        {data?.forceVerify ? (
          <Tooltip
            title={"ForceFully Verify"}
            componentsProps={{
              tooltip: {
                sx: {
                  bgcolor: "grey.300",
                },
              },
            }}
          >
            <Icon
              icon="mdi:shield"
              width={"15px"}
              style={{ color: theme.palette.warning.main }}
            />
          </Tooltip>
        ) : data?.isMobileVerified === 1 ? (
          <Tooltip
            title={"Verified"}
            componentsProps={{
              tooltip: {
                sx: {
                  bgcolor: "grey.300",
                },
              },
            }}
          >
            <Icon
              icon="bitcoin-icons:verify-filled"
              width={"20px"}
              style={{ color: theme.palette.success.main }}
            />
          </Tooltip>
        ) : (
          <Tooltip
            title={"Unverified"}
            componentsProps={{
              tooltip: {
                sx: {
                  bgcolor: "grey.300",
                },
              },
            }}
          >
            <Icon
              icon="mdi:cross-circle-outline"
              width={"15px"}
              style={{ color: theme.palette.error.main }}
            />
          </Tooltip>
        )}
      </Box>
    ) : (
      "--"
    );
  };

  const userChip = (id, align, value) => {
    return value ? (
      <Tooltip
        title={"Check to copy"}
        componentsProps={{
          tooltip: {
            sx: {
              bgcolor: "grey.300",
            },
          },
        }}
      >
        <Typography
          variant="inherit"
          onClick={() => {
            copyToClipBoard(value);
            renderSuccessMessage("UserId copied!");
          }}
        >
          {value}
        </Typography>
      </Tooltip>
    ) : (
      "--"
    );
  };

  const emailChip = (id, align, value, data) => {
    return value ? (
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          gap: "7px",
          alignItems: "center",
          justifyContent: "flex-start",
        }}
      >
        <Tooltip
          title={"Check to copy"}
          componentsProps={{
            tooltip: {
              sx: {
                bgcolor: "grey.700",
              },
            },
          }}
        >
          <Typography
            variant="inherit"
            onClick={() => {
              copyToClipBoard(value);
              renderSuccessMessage("Email copied!");
            }}
          >
            {value}
          </Typography>
        </Tooltip>

        {data?.forceVerify ? (
          <Tooltip
            title={"ForceFully Verify"}
            componentsProps={{
              tooltip: {
                sx: {
                  bgcolor: "grey.300",
                },
              },
            }}
          >
            <Icon
              icon="mdi:shield"
              width={"15px"}
              style={{ color: theme.palette.warning.main }}
            />
          </Tooltip>
        ) : data?.isEmailVerified === 1 ? (
          <Tooltip
            title={"Verified"}
            componentsProps={{
              tooltip: {
                sx: {
                  bgcolor: "grey.300",
                },
              },
            }}
          >
            <Icon
              icon="bitcoin-icons:verify-filled"
              width={"20px"}
              style={{ color: theme.palette.success.main }}
            />
          </Tooltip>
        ) : (
          <Tooltip
            title={"Unverified"}
            componentsProps={{
              tooltip: {
                sx: {
                  bgcolor: "grey.300",
                },
              },
            }}
          >
            <Icon
              icon="mdi:cross-circle-outline"
              width={"15px"}
              style={{ color: theme.palette.error.main }}
            />
          </Tooltip>
        )}
      </Box>
    ) : (
      "--"
    );
  };

  const actionsChip = (id, align, value, row) => {
    return (
      <IconButton
        id={id}
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={(e) => handleClick(e, row)}
        sx={{
          height: "28px",
          width: "32px",
        }}
      >
        <Icon
          icon="fluent:more-vertical-16-filled"
          color={theme.palette.primary.main}
          width={"25px"}
        />
      </IconButton>
    );
  };

  const verificationChip = (id, align, value, data) => {
    return value ? (
      <Typography>Manual</Typography>
    ) : data?.verified ? (
      <Typography>Self</Typography>
    ) : (
      "--"
    );
  };

  const nameChip = (id, align, name) => {
    return (
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          gap: "7px",
          alignItems: "center",
          justifyContent: "flex-start",
        }}
      >
        <Tooltip
          title={"Check to copy"}
          componentsProps={{
            tooltip: {
              sx: {
                bgcolor: "grey.300",
              },
            },
          }}
        >
          <Typography
            variant="inherit"
            onClick={() => {
              copyToClipBoard(name);
              renderSuccessMessage("Name copied!");
            }}
          >
            {nameFormater(name)}
          </Typography>
        </Tooltip>
      </Box>
    );
  };

  const userNameChip = (id, align, userName) => {
    return (
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          gap: "7px",
          alignItems: "center",
          justifyContent: "flex-start",
        }}
      >
        <Tooltip
          title={"Check to copy"}
          componentsProps={{
            tooltip: {
              sx: {
                bgcolor: "grey.300",
              },
            },
          }}
        >
          <Typography
            variant="inherit"
            onClick={() => {
              copyToClipBoard(userName);
              renderSuccessMessage("User name copied!");
            }}
          >
            {nameFormater(userName)}
          </Typography>
        </Tooltip>
      </Box>
    );
  };

  const originChip = (id, align, value) => nameFormater(value);

  const createdAtChip = (id, align, value) => {
    const { date, time } = dateFormate(value);

    return date + ", " + time;
    // nameFormater(value)
  };

  const permissionChip = (id, align, value, row) => {
    const { productPermissions } = row;

    const consolePermission = productPermissions.find(({ productName }) => productName === 'Console') || {};

    return (
      <Tooltip
        title={consolePermission?.permission === "l4" ? "Admin (L4)" : "User (L1)"}
        componentsProps={{
          tooltip: {
            sx: {
              bgcolor: "grey.300",
            },
          },
        }}
      >
        {consolePermission?.permission === "l4" ? "L4" : "L1"}
      </Tooltip>
    );
  }

  const defaultRowJsx = (id, align, value) =>
    value !== undefined ? value : "--";

  const tableRowData = {
    name: nameChip,
    userStatus: statusChip,
    mobile: mobileChip,
    email: emailChip,
    userId: userChip,
    password: passwordChip,
    isGoogleLogin: gLoginChip,
    actions: actionsChip,
    verifiedForcefully: verificationChip,
    origin: originChip,
    permission: permissionChip,
    createdAt: createdAtChip,
    userName: userNameChip,
  };

  React.useEffect(() => {
    if (!passwordTimer) {
      clearInterval(passwordInterval);
      setPasswordEmail("");
      setPasswordInterval("");
      setPassword("");
    }

    //eslint-disable-next-line
  }, [passwordTimer]);

  return (
    <Paper sx={{ width: "100%", overflow: "hidden", borderRadius: "0px" }}>
      <TableContainer
        sx={{
          maxHeight: window.innerHeight - calcSize, //225//'calc(100% - 200px)' //684
        }}
      >
        <Table stickyHeader aria-label="sticky table" size="small">
          <TableHead
            sx={{
              backgroundColor: "grey.400",
              borderTop: "1px solid",
              borderBottom: "1px solid",
              borderColor: "grey.400",
            }}
          >
            <TableRow>
              {columns.map((column) => (
                <CustomTableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </CustomTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {!isListLoading &&
              !!usersList.length &&
              usersList.map((row, index) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                    {columns.map((column) => {
                      const id = column.id;
                      const value = row[id];
                      return (
                        <TableCell
                          key={id}
                          align={column.align}
                          sx={{ color: "grey.700", fontWeight: 500 }}
                        >
                          {column.format && typeof value === "number"
                            ? column.format(value)
                            : (tableRowData?.[id] || defaultRowJsx)(
                              id,
                              column?.align,
                              row[id],
                              row,
                              index
                            )}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}

            {/* Loading case */}
            {isListLoading && (
              <>
                <TableRow hover role="checkbox" tabIndex={-1}>
                  {columns.map((column) => {
                    const id = column.id;
                    return (
                      <TableCell
                        key={id}
                        align={column.align}
                        sx={{ color: "grey.300", fontWeight: 500 }}
                      >
                        <Typography
                          component="div"
                          key={"body1"}
                          variant={"body1"}
                          sx={{ maxWidth: "70%" }}
                        >
                          <Skeleton />
                        </Typography>
                      </TableCell>
                    );
                  })}
                </TableRow>
                <TableRow hover role="checkbox" tabIndex={-1}>
                  {columns.map((column) => {
                    const id = column.id;
                    return (
                      <TableCell
                        key={id}
                        align={column.align}
                        sx={{ color: "grey.300", fontWeight: 500 }}
                      >
                        <Typography
                          component="div"
                          key={"body1"}
                          variant={"body1"}
                          sx={{ maxWidth: "70%" }}
                        >
                          <Skeleton />
                        </Typography>
                      </TableCell>
                    );
                  })}
                </TableRow>
              </>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {!usersList.length && !isListLoading && (
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "200px",
          }}
        >
          <Typography>No more data found!</Typography>
        </Box>
      )}

      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={count}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        SelectProps={{
          MenuProps: {
            sx: {
              ".MuiPaper-root": {
                color: "grey.600",
              },
            },
          },
        }}
        sx={{
          color: "grey.600",
          borderTop: "1px solid",
          borderColor: "grey.300",
        }}
      />

      <ActionMenu
        anchorEl={anchorEl}
        open={open}
        handleClose={handleCloseActions}
        data={actionData}
        fetchUsersList={fetchUsersList}
        type={type}
      />
    </Paper>
  );
}

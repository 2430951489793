import axios from 'axios';
import { configFile } from '../config';
// import ErrorMessage from '../utils/ErrorMessage.json';
import PropTypes from 'prop-types';
import { getCurrentSessionToken, prepareMessageFromError } from '../utils/common';
import { getItem } from '../lib/cookies';
const { getVBApiUrl, VBEndpoint } = configFile;

// create api post call for all post method apis.
const axiosVBPostCall = async (endPointName, data = {}, completeResponse = false, headers = {}) => {
    try {
        const url = VBEndpoint(endPointName);
        const axInstance = getAxiosInstance();
        const response = await axInstance.post(url, data, headers);

        if (completeResponse) return response;
        return response?.data;
    } catch (error) {
        throw new Error(prepareMessageFromError(error));
    }
}

axiosVBPostCall.propTypes = {
    endPointName: PropTypes.string.isRequired, // Api end point url name added in config.
    data: PropTypes.object, // Method body data.
    completeResponse: PropTypes.bool, // Pass true if you want received completed response insted of data.
}

// create api get call for all get method apis.
const axiosVBGetCall = async (endPointName, data = {}, completeResponse = false, headers = {}) => {
    try {
        const url = VBEndpoint(endPointName);
        const axInstance = getAxiosInstance();
        const response = await axInstance.get(url, { params: data });

        if (completeResponse) return response;
        return response?.data;

    } catch (error) {
        throw new Error(prepareMessageFromError(error));
    }
}

const getAxiosInstance = () => {
    const token = getCurrentSessionToken();
    return axios.create({
        baseURL: getVBApiUrl(),
        headers: {
            "session-token": token ?? "",
        }
    });
}

axiosVBGetCall.propTypes = {
    endPointName: PropTypes.string.isRequired, // Api end point url name added in config.
    data: PropTypes.object, // Method body data.
    completeResponse: PropTypes.bool, // Pass true if you want received completed response insted of data.
}

const axiosVBPatchCall = async (endPointName, data = {}, completeResponse = false) => {
    try {
        const url = VBEndpoint(endPointName);
        const axInstance = getAxiosInstance();
        const response = await axInstance.patch(url, data);

        if (completeResponse) return response;
        return response?.data;
        // if (response?.statusText === 'OK') {
        //   return response?.data;
        // }
        // throw new Error(ErrorMessage?.[4000]);
    } catch (error) {
        throw new Error(prepareMessageFromError(error));
    }
};

axiosVBPatchCall.propTypes = {
    endPointName: PropTypes.string.isRequired, // Api end point url name added in config.
    data: PropTypes.object, // Method body data.
    completeResponse: PropTypes.bool, // Pass true if you want received completed response insted of data.
}

export {
    axiosVBPostCall,
    axiosVBGetCall,
    axiosVBPatchCall
}
import axios from 'axios';
import { configFile } from '../config';
// import ErrorMessage from '../utils/ErrorMessage.json';
import PropTypes from 'prop-types';
import axiosIntance from '../lib/axios';
import { decodeToken, prepareMessageFromError } from '../utils/common';
import Logger from '../lib/Logger';
import { removeBulkItems } from '../lib/cookies';
const { endPoints, getApiUrl } = configFile;
const logger = new Logger("apisCall")

class CustomError extends Error {
    constructor(message, status) {
        super(message);
        this.name = "CustomError";
        this.status = status;
    }
}

// create api post call for all post method apis.
const axiosPostCall = async (endPointName, data = {}, completeResponse = false, headers = {}, query) => {
    try {
        const url = endPoints(endPointName);
        const axInstance = Object.keys(headers).length ? getAxiosInstance(headers) : axiosIntance;
        const response = await axInstance.post(url, data, headers);


        if (completeResponse) return response;
        return response?.data;

        /* if (response?.statusText === "OK") {
            return response?.data;
        }
        throw new Error(ErrorMessage?.[4000]); */
    } catch (error) {
        throw new Error(prepareMessageFromError(error));
    }
}

axiosPostCall.propTypes = {
    endPointName: PropTypes.string.isRequired, // Api end point url name added in config.
    data: PropTypes.object, // Method body data.
    completeResponse: PropTypes.bool, // Pass true if you want received completed response insted of data.
}
const axiosPostCallWithStatusCode = async (endPointName, data = {}, completeResponse = false, headers = {}, query) => {
    try {
        const url = endPoints(endPointName);
        const axInstance = Object.keys(headers).length ? getAxiosInstance(headers) : axiosIntance;
        const response = await axInstance.post(url, data, headers);


        if (completeResponse) return response;
        return response?.data;

        /* if (response?.statusText === "OK") {
            return response?.data;
        }
        throw new Error(ErrorMessage?.[4000]); */
    } catch (error) {
        if (error.response) {
            throw error;
        } else {
            // If it's not an Axios error, throw the generic error
            throw new Error(error.message || 'An unknown error occurred');
        }
    }
}
// create api get call for all get method apis.
const axiosGetCall = async (endPointName, data = {}, completeResponse = false, headers = {}) => {
    try {
        const url = endPoints(endPointName);
        const axInstance = Object.keys(headers).length ? getAxiosInstance(headers) : axiosIntance;
        const response = await axInstance.get(url, { params: data });

        if (completeResponse) return response;
        return response?.data;

        /*  if (response?.statusText === "OK") {
             return response?.data;
         }
         throw new Error(ErrorMessage?.[4000]); */
    } catch (error) {
        throw new Error(prepareMessageFromError(error));
    }
}

const getAxiosInstance = (headers) => {
    const axiosInstance = axios.create({
        baseURL: getApiUrl(),
        headers,
    });

    axiosInstance.interceptors.response.use(function (response) {
        return response;
    }, function (error) {
        const status = error?.response?.status;
        if (status === 713) {
            removeBulkItems([{ key: "sessionToken" }, { key: "exp" }, { key: "token" }])
            console.log("713 working ")
            localStorage.removeItem("branding");
            window.location.href = "/login";
        }
        return Promise.reject(error);
    });

    return axiosInstance;
}

axiosGetCall.propTypes = {
    endPointName: PropTypes.string.isRequired, // Api end point url name added in config.
    data: PropTypes.object, // Method body data.
    completeResponse: PropTypes.bool, // Pass true if you want received completed response insted of data.
}

const handleLogout = async () => {
    try {
        await axiosPostCall("logout");
        if (window.self !== window.top) {
            const message = { logout: true }
            window.parent.postMessage(message, "*");
        }
        localStorage.removeItem("branding");
        return;
    }
    catch (error) {
        logger.error("apisCall.js [handleLogout] catchError", error);
        throw new Error(error);
    }
}

export {
    axiosPostCall,
    axiosGetCall,
    handleLogout,
    axiosPostCallWithStatusCode
}
import React, { useState, useEffect } from "react";
import { handleLogout } from "../services/apisCall";
import Logger from "../lib/Logger";
import { removeBulkItems, removeItem } from "../lib/cookies";
const logger = new Logger("logout");

const Logout = () => {
    const [isLoading, setIsLoading] = useState(true); // State to track loading

    const logout = async () => {
        try {
            await handleLogout(); // API call for logout
            if (window.self === window.top) {
                removeBulkItems([{ key: "sessionToken" }, { key: "token" }, { key: "exp" }, { key: "secureToken" }]);
                removeItem("secureToken");
                localStorage.removeItem("branding");
                window.location.href = "/login";
            }
        } catch (error) {
            logger.error("Logout.jsx [logout] catchError", error);
        }
    }

    useEffect(() => {
        logout();
        setTimeout(() => setIsLoading(false), 3000); // Simulate delay for demo purposes
    }, []);

    return (
        <>
        </>
    );
}

export default Logout;


// eslint-disable-next-line
const CalendarIcon = ({ color }) => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_2480_31075)">
            <path d="M21.992 2.31H18.829V0.703C18.829 0.314 18.515 0 18.126 0C17.737 0 17.423 0.314 17.423 0.703V2.31H6.678V0.703C6.678 0.314 6.364 0 5.975 0C5.586 0 5.272 0.314 5.272 0.703V2.31H2.008C0.9 2.31 0 3.208 0 4.318V21.992C0 23.101 0.9 24 2.008 24H21.991C23.101 24 23.999 23.1 23.999 21.992V4.318C23.999 3.208 23.1 2.31 21.991 2.31H21.992ZM22.594 21.992C22.594 22.324 22.324 22.595 21.991 22.595H2.008C1.676 22.595 1.405 22.325 1.405 21.992V9.641H22.593V21.992H22.594ZM22.594 8.234H1.406V4.318C1.406 3.986 1.676 3.715 2.009 3.715H5.273V5.322C5.273 5.711 5.587 6.025 5.976 6.025C6.365 6.025 6.679 5.711 6.679 5.322V3.715H17.424V5.322C17.424 5.711 17.738 6.025 18.127 6.025C18.516 6.025 18.83 5.711 18.83 5.322V3.715H21.993C22.325 3.715 22.596 3.985 22.596 4.318V8.234H22.594Z" fill="#626F86" />
            <path d="M6.70195 12.55H6.69995C6.09295 12.55 5.60095 13.042 5.60095 13.649V13.651C5.60095 14.258 6.09295 14.75 6.69995 14.75H6.70195C7.30895 14.75 7.80095 14.258 7.80095 13.651V13.649C7.80095 13.042 7.30895 12.55 6.70195 12.55Z" fill="#626F86" />
            <path d="M12.0009 12.55H11.9989C11.3919 12.55 10.8999 13.042 10.8999 13.649V13.651C10.8999 14.258 11.3919 14.75 11.9989 14.75H12.0009C12.6079 14.75 13.0999 14.258 13.0999 13.651V13.649C13.0999 13.042 12.6079 12.55 12.0009 12.55Z" fill="#626F86" />
            <path d="M17.301 12.55H17.299C16.692 12.55 16.2 13.042 16.2 13.649V13.651C16.2 14.258 16.692 14.75 17.299 14.75H17.301C17.908 14.75 18.4 14.258 18.4 13.651V13.649C18.4 13.042 17.908 12.55 17.301 12.55Z" fill="#626F86" />
            <path d="M6.70195 17.55H6.69995C6.09295 17.55 5.60095 18.042 5.60095 18.649V18.651C5.60095 19.258 6.09295 19.75 6.69995 19.75H6.70195C7.30895 19.75 7.80095 19.258 7.80095 18.651V18.649C7.80095 18.042 7.30895 17.55 6.70195 17.55Z" fill="#626F86" />
            <path d="M12.0009 17.55H11.9989C11.3919 17.55 10.8999 18.042 10.8999 18.649V18.651C10.8999 19.258 11.3919 19.75 11.9989 19.75H12.0009C12.6079 19.75 13.0999 19.258 13.0999 18.651V18.649C13.0999 18.042 12.6079 17.55 12.0009 17.55Z" fill="#626F86" />
            <path d="M17.301 17.55H17.299C16.692 17.55 16.2 18.042 16.2 18.649V18.651C16.2 19.258 16.692 19.75 17.299 19.75H17.301C17.908 19.75 18.4 19.258 18.4 18.651V18.649C18.4 18.042 17.908 17.55 17.301 17.55Z" fill="#626F86" />
        </g>
        <defs>
            <clipPath id="clip0_2480_31075">
                <rect width="24" height="24" fill="white" />
            </clipPath>
        </defs>
    </svg>
)


export default CalendarIcon;
import React, { useEffect, useState } from 'react'
import MyPlansSection from '../sections/plans/myPlansSection';
import { Typography, Box, Button } from '@mui/material';
import { styled } from '@mui/material/styles';

import { ToastContainer } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { axiosGetCall } from '../services/apisCall';
import Logger from '../lib/Logger';
const logger = new Logger("myPlans");

const MainSection = styled('div')(({ theme }) => ({
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    // justifyContent: 'flex-start',
    alignItems: 'center',
    flexDirection: 'column',
    // gap: '14px',
    padding: '62px 12px 12px 72px',
    height: '100%',
    overflowY: 'scroll',
    [theme.breakpoints.down('sm')]: {
        padding: '62px 14px 14px 14px',
        height: 'auto',
    },
}));
const Card = styled("div")(({ theme }) => ({
    height: "auto",
    maxHeight: "calc(100%)",
    display: "flex",
    flexDirection: "column",
    borderRadius: "6px",
    border: "1px solid",
    overflow: "hidden",
    backgroundColor: theme.palette.card.main,
    borderColor: theme.palette.grey[300],
    width: "100%",
    [theme.breakpoints.down("sm")]: {
        width: "100%",
    },
}));

const HeadingSection = styled("div")(({ theme }) => ({
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: "12px",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
        alignItems: "flex-start",
        gap: "10px",
        flexWrap: "wrap",
    },
}));

const HeadingTypography = styled(Typography)(({ theme }) => ({
    color: theme.palette.grey[700],
    fontSize: "20px",
    fontWeight: 600,
}));

const TableSection = styled("div")(() => ({
    // height: 'calc(100% - 30px)'
    // padding: '0px 0px 0px 0px'
}));


const MyPlans = () => {
    const [plans, setPlans] = useState([]);
    const [load, setLoad] = useState(false);
    const [from, setFrom] = useState(0);    
    const [count, setCount] = useState(0);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const navigate = useNavigate()
    const fetchPlanDetails = async () => {
        try{
            const { data = [], count =0 } = await axiosGetCall("assignedPlanDetails", { from }) || [];
            setPlans(data);
            setCount(count)
        } catch(error) {
            logger.error(error);
        }
    }
    useEffect(() => {
        fetchPlanDetails();
    }, [load])

    return (
        <MainSection>
            <ToastContainer />

            <Card>
                <HeadingSection>
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "row",
                            gap: "10px",
                            alignItems: "center",
                        }}
                    >
                        <HeadingTypography>Licences</HeadingTypography>

                        {/* {!!filterLabels.length && (
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "row",
                                gap: "6px",
                                alignItems: "center",
                                flexWrap: "wrap",
                            }}
                        >
                            {filterLabels.map((item) => (
                                    <Tooltip title={generateLabelChipToolTip(item)}>
                                        <Chip label={item?.key} variant="outlined" size="small" />
                                    </Tooltip>
                                ))}

                            <Button
                                variant="outlined"
                                size="small"
                                color="warning"
                                onClick={() => resetFilters()}
                            >
                                Clear Filter
                            </Button>
                        </Box>
                       )}  */}
                    </Box>

                    <Button
                        variant='outlined'
                        color='primary'
                        onClick={() => process.env.REACT_APP_ENV !== "production" && navigate('/plans')}
                    >
                        Upgrade Now
                    </Button>

                </HeadingSection>
                {/* count, isListLoading, fetchUsersList, page, setPage, rowsPerPage, setRowsPerPage, setLoad, calcSize = 201 */}
                <TableSection>
                    <MyPlansSection
                        usersList={plans}
                        count={count}
                        rowsPerPage={rowsPerPage}
                        setRowsPerPage={setRowsPerPage}
                        page={page}
                        setPage={setPage}
                    // page={page}
                    // setPage={setPage}
                    // rowsPerPage={rowsPerPage}
                    // setRowsPerPage={setRowsPerPage}
                    // setLoad={setLoad}
                    // usersList={usersList}
                    // count={count}
                    // isListLoading={isListLoading}
                    // fetchUsersList={fetchUsersList}
                    />
                </TableSection>
            </Card>
        </MainSection>
    )
}

export default MyPlans
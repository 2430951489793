import React, { useState } from "react";

//mui
import Grid from '@mui/material/Unstable_Grid2';

//components
import LoginForm from "../../../components/Auth/Login";
import LoadingModal from "../../../components/Loader/loading";

export default function Login() {
    const [loader, setLoader] = useState(false);
    return (
        <>
            {loader && <LoadingModal open={true} />}
            <Grid sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100%',
                background: {
                    xs: '#fff',
                    sm: '#F7F8F9'
                },
            }}>
                <LoginForm handleLoader={setLoader} />
            </Grid>
        </>
    )
}

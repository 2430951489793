import { styled } from "@mui/material/styles";
import { Typography, Box } from "@mui/material";
import { ToastContainer } from "react-toastify";
import ProfilePicture from "../sections/users/profilePicture";
import NewUserForm from "../sections/users/form";
import { useEffect, useState } from "react";
import { checkPermission, compareObjectValues, decodeToken } from "../utils/common";
import { useLocation, useNavigate } from "react-router-dom";
import { axiosPostCall, axiosGetCall } from "../services/apisCall";
import { useParams } from "react-router-dom";
import { renderErrorMessage, renderSuccessMessage } from "../lib/toastMessage";
import { getItem } from "../lib/cookies";
import jwtDecode from "jwt-decode";
import { useSelector } from "react-redux";

const MainSection = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
  flexDirection: "column",
  gap: "14px",
  // padding: '76px 12px 12px 70px',
  padding: "62px 12px 12px 72px",
  height: "100%",
  overflowY: "scroll",
  // overflow: 'hidden',
  [theme.breakpoints.down("sm")]: {
    padding: "62px 12px 12px 72px",
    height: "auto",
  },
}));

const Card = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  borderRadius: "6px",
  border: "1px solid",
  overflow: "hidden",
  backgroundColor: theme.palette.card.main,
  borderColor: theme.palette.grey[300],
  width: "40%",
  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
}));

const HeadingTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.grey[700],
  fontSize: "20px",
  fontWeight: 600,
}));

const FormSection = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  width: "100%",
  gap: "10px",
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
  },
}));

const NewUsers = () => {
  const navigate = useNavigate();
  const param = useParams();

  const [verified, setVeried] = useState(false);
  const [image, setImage] = useState(null);
  const [edit, setEdit] = useState(false);
  const [userDetails, setUserDetails] = useState({});
  const [planList, setPlanList] = useState([]);
  const [workSpaceFolderData, setWorkSpaceFolderData] = useState([]);
  const [employeeList, setEmployeeList] = useState([]);

  const workspace = getItem("workspace");
  const location = useLocation();
  const { stepName } = location.state || {};
  const token = decodeToken();

  const workspaceDetails = useSelector(
    (state) => state.workspace.workspaceDetails
  );

  const createNewUser = async (data) => {
    try {
      const {
        name,
        email,
        contact,
        password,
        address,
        city,
        code,
        role,
        permission,
        products,
        gender,
        maritalStatus,
        relationManager,
        folder,
        flag,
        dDate,
        optionalParameters,
        userName,
        loginLimit,
        productPermissions,
      } = data;

      const formData = new FormData();

      const dataObj = {
        name,
        email,
        password,
        mobile: contact,
        countryCode: "91",
        address,
        zipcode: code,
        city,
        role,
        permission,
        relationManager,
        folder,
        workspace: workspace,
        om: param.id,
        products,
        gender,
        suspendTime: dDate,
        flag,
        maritalStatus,
        forceVerify: verified,
        profileName: image?.name || "",
        optionalParameters,
        userName,
        loginLimit,
        firstEmail: stepName === "Set Up Email Accounts" ? true : false,
        productPermissions,
      };

      formData.append("data", JSON.stringify(dataObj));
      formData.append("profilePhoto", image);

      if (!edit) {
        const { message } = await axiosPostCall("createUser", formData);
        renderSuccessMessage(message || "New user crated successfully");

        param.id === "employee"
          ? navigate("/employeeList")
          : navigate("/users");
      } else {
        if (!compareObjectValues(userDetails, dataObj)) {
          return renderErrorMessage("No thing has been changed for update.");
        }
        formData.append("email", userDetails?.email);
        const { message } = await axiosPostCall("updateUser", formData);
        renderSuccessMessage(message || "New user crated successfully");

        param.id === "employee"
          ? navigate("/employeeList")
          : navigate("/users");
      }
    } catch (error) {
      console.log(error);
      renderErrorMessage(error?.message || "Network error!");
    }
  };

  useEffect(() => {
    fetchPlanDetails();
    fetchWorkspaceFolder();
    fetchorganistationEmployees();
    const permission = checkPermission();
    if (permission !== "l4") return navigate("/");
    const { edit = false, userDetails = {} } = location?.state || {};

    if (edit) {
      setEdit(edit);
      const { forceVerify } = userDetails;
      setVeried(forceVerify);

      setUserDetails(userDetails);
    }

    //eslint-disable-next-line
  }, []);
  const fetchPlanDetails = async () => {
    try {
      const { data } = await axiosGetCall("fetchPlans", {
        userId: token?.userId,
      });

      setPlanList(data);
    } catch (error) {
      console.log(error);
    }
  };
  const fetchWorkspaceFolder = async () => {
    try {
      const { data } = await axiosGetCall("fetchWorkspaceFolder", {
        userId: token?.userId,
        workspaceId: workspace,
      });

      setWorkSpaceFolderData(data);
    } catch (error) {
      console.log(error);
    }
  };
  const fetchorganistationEmployees = async () => {
    try {
      const { usersList } = await axiosGetCall("employeeList", {
        useremail: token?.email,
      });

      setEmployeeList(usersList);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {

    if (param.id === "employee") {
      if (workspaceDetails?.status !== "completed" && stepName !== "Set Up Email Accounts") {
        navigate("/")
      }
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workspaceDetails])

  return (
    <MainSection>
      <ToastContainer />

      <Box sx={{ width: "100%", mt: "-14px" }}>
        {param.id === "employee" ? (
          <HeadingTypography>
            {edit ? "Update Employee" : "Create new Employee"}
          </HeadingTypography>
        ) : (
          <HeadingTypography>
            {edit ? "Update Customers" : "Create new Customers"}
          </HeadingTypography>
        )}
      </Box>

      <FormSection>
        <Card
          sx={{
            maxHeight: "420px",
            minHeight: "420px",
            justifyContent: "center",
          }}
        >
          <ProfilePicture
            verified={verified}
            setVeried={setVeried}
            image={image}
            setImage={setImage}
          />
        </Card>

        <Card sx={{ width: "100%" }}>
          <NewUserForm
            handleCreateNewUser={createNewUser}
            type={param.id}
            edit={edit}
            userDetails={userDetails}
            plans={planList}
            workSpaceFolderData={workSpaceFolderData}
            employeeList={employeeList}
            workspace={workspace}
          />
        </Card>
      </FormSection>
    </MainSection>
  );
};

export default NewUsers;

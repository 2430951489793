const ContactsActiveIcon = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_2480_31068)">
            <path d="M23.608 3.255L21 2.289V0H2C0.895 0 0 0.895 0 2V22C0 23.105 0.895 24 2 24H21V21.711L23.608 20.745C23.844 20.658 24 20.433 24 20.182V3.818C24 3.567 23.844 3.342 23.608 3.255ZM19.6 22.6H2C1.669 22.6 1.4 22.331 1.4 22V2C1.4 1.669 1.669 1.4 2 1.4H19.6V22.6ZM22.6 19.626L21 20.219V17.958L22.6 17.365V19.626ZM22.6 15.872L21 16.465V14.258L22.6 13.665V15.872ZM22.6 12.172L21 12.765V10.558L22.6 9.965V12.172ZM21 9.065V3.781L22.6 4.374V8.472L21 9.065Z" fill="#0C66E4" />
            <path d="M15.508 12.968L13.881 12.317C13.25 12.065 12.531 12.179 12.008 12.614L11.588 12.964C11.343 13.169 10.982 13.152 10.757 12.927L9.573 11.743C9.348 11.518 9.331 11.157 9.536 10.912L9.886 10.492C10.321 9.969 10.435 9.25 10.183 8.619L9.532 6.992C9.251 6.289 8.57 5.828 7.813 5.828H6.115C5.128 5.828 4.328 6.628 4.328 7.615C4.328 13.445 9.055 18.172 14.885 18.172C15.872 18.172 16.672 17.372 16.672 16.385V14.687C16.672 13.93 16.211 13.249 15.508 12.968ZM14.885 16.938C9.736 16.938 5.562 12.764 5.562 7.615C5.562 7.31 5.81 7.062 6.115 7.062H7.813C8.065 7.062 8.292 7.216 8.386 7.45L9.036 9.077C9.121 9.288 9.083 9.527 8.938 9.701L8.587 10.122C7.974 10.857 8.023 11.939 8.7 12.616L9.884 13.8C10.561 14.477 11.643 14.526 12.378 13.913L12.799 13.562C12.973 13.417 13.212 13.379 13.423 13.464L15.05 14.114C15.284 14.208 15.438 14.435 15.438 14.687V16.385C15.438 16.69 15.19 16.938 14.885 16.938Z" fill="#E56910" />
        </g>
        <defs>
            <clipPath id="clip0_2480_31068">
                <rect width="24" height="24" fill="white" />
            </clipPath>
        </defs>
    </svg>
)

export default ContactsActiveIcon;
import React, { useState, useEffect, useRef } from "react";
import {
    Box,
    Typography,
    TextField,
    InputAdornment,
    IconButton,
    styled,
    SvgIcon
} from "@mui/material";
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import { MuiOtpInput } from 'mui-one-time-password-input';
import { useNavigate } from "react-router-dom";

import ErrorMessage from '../../../utils/ErrorMessage.json';
import { axiosPostCall } from "../../../services/apisCall";
import { validatePassword } from "../../../utils/common";
import Logger from "../../../lib/Logger";
import AuthFooter from "../../../layouts/AuthFooter";
import { renderErrorMessage } from "../../../lib/toastMessage";
import { ToastContainer } from "react-toastify";
import LogoBlue from "../../../assets/svgs/logo";
import { ArrowLeftIcon } from "@mui/x-date-pickers";
import TimeLineIcon from "../../../assets/svgs/icons/time-line";

const HeadingSection = styled('div')(({ theme }) => ({
    width: '630px',
    display: 'flex',
    background: '#FFFFFF',
    padding: '30px 40px',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '26px',
    [theme.breakpoints.down('sm')]: {
        borderRadius: '0px',
        width: 'auto',
        padding: '20px',
    }
}));

const MuiOtpInputStyled = styled(MuiOtpInput)(({ theme }) => ({
    display: 'flex',
    gap: '30px',
    maxWidth: '650px',
    marginInline: 'auto',
    [theme.breakpoints.down('sm')]: {
        gap: '4px',
        // ".MuiOtpInput-TextField	": {
        //     padding: '4px'
        // }
    }
}))


const ResetPasswordForm = ({ setOpenResetPassword, userId }) => {
    const navigate = useNavigate();

    const logger = new Logger('Reset-Password');

    const [otp, setOtp] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [newPassword, setNewPassword] = useState("");
    const [newPasswordError, setNewPasswordError] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [confirmPasswordError, setConfirmPasswordError] = useState("");
    const [pwdLength, setPwdLength] = useState(true);
    const [pwdNumber, setPwdNumber] = useState(true);
    const [pwdUppercase, setPwdUppercase] = useState(true);
    const [pwdLowercase, setPwdLowercase] = useState(true);
    const [pwdSymbol, setPwdSymbol] = useState(true);
    const [timer, setTimer] = useState(60);
    const [isResendDisabled, setIsResendDisabled] = useState(true);
    const [resetLoading, setResetLoading] = useState(false);
    const [showPasswordSection, setShowPasswordSection] = useState(false)
    const inputRef = useRef();

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleResetPassword = async (e) => {
        try {
            e.preventDefault();
            if (!otp || otp.length !== 6 || otp.includes(" ")) return renderErrorMessage(ErrorMessage?.[4008]);
            if (!newPassword) return setNewPasswordError("blank");

            (newPassword.length >= 8 && newPassword.length <= 20) ? setPwdLength(true) : setPwdLength(false);
            (/[0-9]/.test(newPassword)) ? setPwdNumber(true) : setPwdNumber(false);
            (/[A-Z]/.test(newPassword)) ? setPwdUppercase(true) : setPwdUppercase(false);
            (/[a-z]/.test(newPassword)) ? setPwdLowercase(true) : setPwdLowercase(false);
            // eslint-disable-next-line
            (/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/).test(newPassword) ? setPwdSymbol(true) : setPwdSymbol(false);

            if (!validatePassword(newPassword)) return renderErrorMessage(ErrorMessage?.[4005]);
            if (newPassword !== confirmPassword) return setConfirmPasswordError("invalid");

            setResetLoading(true);

            const payload = { otp, newPassword, confirmPassword, userId };
            const { message } = await axiosPostCall("resetPassword", payload);

            return navigate('/login', { state: { message, time: +new Date() + 10 * 1000 } });
        } catch (error) {
            logger.error(error.message)
            renderErrorMessage(error.message)
            setResetLoading(false)
        }
    }

    const handleResendOtp = async (e) => {
        try {
            if (!isResendDisabled) {

                e.preventDefault();

                await axiosPostCall("forgotPassword", { userId });

                setTimer(60);
                setIsResendDisabled(true);
            }

        } catch (error) {
            logger.error(error.message)
            renderErrorMessage(error.message)
        }
    }

    const handleOtpChange = (newValue) => setOtp(newValue)
    const handleChange = (e) => e.preventDefault();

    // const changeUserId = () => setOpenResetPassword(false)

    useEffect(() => {
        let interval;
        if (timer > 0 && isResendDisabled) {
            interval = setInterval(() => {
                setTimer((prevTimer) => prevTimer - 1);
            }, 1000);
        } else {
            clearInterval(interval);
            setIsResendDisabled(false);
        }

        return () => clearInterval(interval);
    }, [timer, isResendDisabled]);

    const handleFormSubmit = (e) => e.preventDefault()

    const onKeyDown = (e) => {
        console.log(e.key)
        if (e.key === "Tab") {
            e.preventDefault();
            inputRef.current.focus();
        }
    };

    return (
        <HeadingSection>
            <ToastContainer />
            <Box className='FormContent' sx={{
                textAlign: { xs: "center", sm: "start", md: "start", lg: 'start' },
                display: 'flex',
                flexDirection: 'column',
                gap: '40px',
                width: '100%',
                mb: {
                    xs: '40px',
                    sm: '55px',
                }
            }}
                onSubmit={handleFormSubmit}
            >

                <Box sx={{
                    display: 'flex',
                    justifyContent: 'center'
                }}>
                    <Box sx={{
                        width: '160px',
                        height: '35px',
                        display: "flex",
                        justifyContent: "center"
                    }}>
                        <LogoBlue isAuth={true} height={35} />
                    </Box>
                </Box>

                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                    gap: '24px'
                }}>
                    {/* <Box className='FormHeading' sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}>
                        <Typography variant="custom24600" sx={{ color: '#282828' }}>
                            Reset your password?
                        </Typography>
                    </Box> */}

                    <Box className="FormGroup" sx={{
                        display: 'flex',
                        gap: '16px',
                        flexDirection: 'column',
                        width: '100%'
                    }}>

                        <Box sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '7px',
                            flexDirection: 'row',
                            justifyContent: 'flex-start'
                        }} onClick={() => navigate('/login')}>
                            <IconButton sx={{
                                width: '25px',
                                height: '25px',
                                color: '#000000',
                                border: '1px solid #000000'
                            }}>

                                <SvgIcon >
                                    <ArrowLeftIcon color="currentColor" />
                                </SvgIcon>
                            </IconButton>


                            <Typography variant="custom24600" sx={{ color: '#000000', fontSize: '17px', fontWeight: 500 }}>
                                Back to login
                            </Typography>
                        </Box>

                        <Box sx={{
                            display: 'flex',
                            alignItems: 'flex-start',
                            gap: '7px',
                            flexDirection: 'column',
                        }}>
                            <Typography variant="custom24600" sx={{
                                color: '#0055CC',
                                fontSize: {
                                    xs: '24px',
                                    sm: '30px',
                                },
                                fontWeight: 500
                            }}>
                                Reset your password!
                            </Typography>
                        </Box>
                        {
                            <>

                                <Box sx={{
                                    display: 'flex',
                                    gap: '6px',
                                    flexDirection: 'column',
                                    alignItems: 'flex-start',
                                }}>

                                    <Typography variant="body2" sx={{
                                        fontSize: '14px',
                                        fontWeight: 500,
                                        color: '#44546F',
                                    }}>Enter OTP</Typography>

                                    <MuiOtpInputStyled
                                        value={otp}
                                        onChange={(e) => handleOtpChange(e)}
                                        length={6}
                                        onComplete={() => setShowPasswordSection(true)}
                                        validateChar={(val) => !isNaN(val)}
                                        // error={true}
                                        // disabled
                                        TextFieldsProps={{ size: 'medium', placeholder: '-' }}
                                        autoFocus
                                        sx={{
                                            width: '100%',
                                            '&.MuiInputBase-input': {
                                                color: '#B3B9C4',
                                            }
                                        }}
                                    />

                                </Box>

                                <Box sx={{
                                    display: 'flex',
                                    gap: '6px',
                                    flexDirection: 'column',
                                    alignItems: 'flex-start',
                                }}>

                                    <Typography
                                        variant="body2"
                                        onClick={handleResendOtp}
                                        sx={{
                                            fontSize: '14px',
                                            fontWeight: 500,
                                            color: isResendDisabled ? '#44546F' : '#0055CC',
                                            display: 'flex',
                                            alignItems: 'center',
                                            cursor: 'pointer',
                                            gap: '5px'
                                        }}>
                                        Resend OTP
                                        {isResendDisabled && <SvgIcon sx={{ width: '16px', height: '16px', color: 'inherit' }}>
                                            <TimeLineIcon color={'currentColor'} />
                                        </SvgIcon>
                                        }
                                        {/*  <Link
                                            underline="none"
                                            style={{ pointerEvents: isResendDisabled ? 'none' : '' }}
                                            color={isResendDisabled ? 'textSecondary' : 'primary'}
                                            href="#">Resend OTP</Link> */}
                                        {isResendDisabled && `(${timer}s)`}
                                    </Typography>
                                </Box>

                                {showPasswordSection && <>
                                    <Box sx={{
                                        display: 'flex',
                                        gap: '10px',
                                        flexDirection: 'column',
                                        alignItems: 'flex-start',
                                    }}>
                                        <Typography variant="body2" sx={{
                                            fontSize: '14px',
                                            fontWeight: 500,
                                            color: '#44546F',
                                        }}>New Password</Typography>

                                        <form style={{ width: '100%' }}>
                                            <TextField
                                                id="outlined-adornment-password"
                                                type={showPassword ? 'text' : 'password'}
                                                error={Boolean(newPasswordError || !pwdLength || !pwdNumber || !pwdUppercase || !pwdLowercase || !pwdSymbol)}
                                                onClick={() => {
                                                    setNewPasswordError("")
                                                    setPwdLength(true)
                                                    setPwdNumber(true)
                                                    setPwdUppercase(true)
                                                    setPwdLowercase(true)
                                                    setPwdSymbol(true)
                                                }}
                                                placeholder={"New Password"}
                                                fullWidth
                                                helperText={newPasswordError === "blank" ? ErrorMessage?.[4002] :
                                                    !pwdLength ? "pasword length should be 8 to 20." :
                                                        !pwdNumber ? "At least 1 Number required." :
                                                            !pwdUppercase ? "At least 1 Uppercase character required" :
                                                                !pwdLowercase ? "At least 1 Lowercase character required" :
                                                                    !pwdSymbol ? "At least 1 special character required" : ""
                                                }
                                                value={newPassword}
                                                onChange={(e) => setNewPassword(e.target.value)}
                                                inputProps={{
                                                    sx: {
                                                        height: '30px',
                                                        fontSize: '14px',
                                                        color: '#B3B9C4',
                                                        borderColor: '#B3B9C4',
                                                        "&.-MuiOutlinedInput-notchedOutline": {
                                                            borderColor: "#fff",
                                                        },
                                                        // "& .MuiOutlinedInput-root": {
                                                        //     borderColor: "#fff",
                                                        //     "&.Mui-focused fieldset": {
                                                        //         borderColor: "#fff",
                                                        //     },
                                                        //     "&.-MuiOutlinedInput-notchedOutline": {
                                                        //         borderColor: "none",
                                                        //     }
                                                        // },
                                                    },
                                                }}
                                                autoComplete="on"
                                                InputProps={{
                                                    endAdornment: <InputAdornment position="end" >
                                                        <IconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={(e) => handleClickShowPassword(e)}
                                                            edge="end"
                                                            sx={{ color: '#44546F' }}
                                                        >
                                                            {showPassword ? <VisibilityOffOutlinedIcon /> : <VisibilityOutlinedIcon />}
                                                        </IconButton>
                                                    </InputAdornment>,
                                                }}
                                                variant="outlined"
                                                size="small"
                                                onKeyDown={onKeyDown}
                                            />
                                        </form>
                                    </Box>

                                    <Box sx={{
                                        display: 'flex',
                                        gap: '6px',
                                        flexDirection: 'column',
                                        alignItems: 'flex-start',
                                    }}>
                                        <Typography variant="custom13500" sx={{
                                            fontSize: '14px',
                                            fontWeight: '500',
                                            color: '#44546F',
                                        }}>Confirm Password</Typography>
                                        <form style={{ width: '100%' }}>
                                            <TextField
                                                id="outlined-adornment-confirm-password"
                                                type={"password"}
                                                error={Boolean(confirmPasswordError)}
                                                onClick={() => confirmPasswordError ? setConfirmPasswordError("") : ""}
                                                onCut={(e) => handleChange(e)}
                                                onCopy={(e) => handleChange(e)}
                                                onPaste={(e) => handleChange(e)}
                                                placeholder={"Confirm Password"}
                                                fullWidth
                                                helperText={confirmPasswordError === "invalid" ? ErrorMessage?.[4010] : ""}
                                                value={confirmPassword}
                                                onChange={(e) => setConfirmPassword(e.target.value)}
                                                inputProps={{
                                                    sx: {
                                                        height: '30px',
                                                        fontSize: '14px',
                                                        color: '#B3B9C4',
                                                        borderColor: '#B3B9C4'
                                                    },
                                                }}
                                                autoComplete="on"
                                                variant="outlined"
                                                size="small"
                                                inputRef={inputRef}

                                            />
                                        </form>
                                    </Box>

                                    <AuthFooter
                                        btnText={"Reset Password"}
                                        navigationMessage={"Back to"}
                                        navigateText={"Login"}
                                        btnCheckHandler={handleResetPassword}
                                        navigationCheckHandler={() => navigate('/login')}
                                        loadingBtn={resetLoading}
                                    />
                                </>}
                            </>
                        }
                    </Box>
                </Box>


            </Box>
        </HeadingSection>
    )
}

export default ResetPasswordForm;
